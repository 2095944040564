import React, { useEffect, useState } from 'react'; 
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Tooltip } from "@material-ui/core";
import { Card } from "../../../../_metronic/_partials/controls";
import { Col, Row } from 'react-bootstrap';
import MyAutocomplete from '../../MyAutocomplete';
import { TableHeader } from '../../../../utils/helpers';
import Table, { buttonsStyle } from '../../tables/table';
import { ArrowDownward, ArrowUpward, Delete, Edit } from '@material-ui/icons';
import { alertError } from '../../../../utils/logger';

export const EditOptionDialog = (props) => {
    const { open, setOpen, onSave, data } = props;
    const [option, setOption] = useState(null);

    useEffect(() => {
        if (data) setOption(data);
        else setOption(null);
    }, [open, data])

    function close() {
        setOpen(false);
        setOption(null);
    }

    const handleChange = (element) => (event) => {
        setOption({...option, [element]: event.target.value});
    }

    return (
        <Dialog disableBackdropClick={true}
            fullWidth={true}
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg"
            aria-labelledby="table-dialog"
        >
            <DialogTitle id="table-dialog">{"Edit option"}</DialogTitle>
            <DialogContent>
                <Row>
                    <Col>
                    <TextField
                        id={`question`}
                        label="Question"
                        onChange={handleChange('question')}
                        value={option?.question}
                        margin="normal"
                        variant="standard"
                        type="text"
                    />
                    </Col>
                </Row>

                <Row>
                    <Col sm={2} style={{marginTop: '35px'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={option?.extra}
                                onChange={() =>
                                    setOption({
                                        ...option,
                                        extra: !option?.extra
                                    })
                                }
                                name="checkActive"
                            />
                        }
                        label="Extra question"
                    />
                    </Col>
                    <Col>
                    <TextField
                        id={`additionalQuestion`}
                        label="Additional question"
                        onChange={handleChange('additionalQuestion')}
                        value={option?.additionalQuestion}
                        margin="normal"
                        variant="standard"
                        type="text"
                        inputProps={{ readOnly: !option?.extra }}
                        className={`${option?.extra ? '' : 'readonly'}`}
                    />
                    </Col>
                </Row>
                <br /><br /><br /><br /><br /><br /><br />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                        close();
                    }}
                >
                    Close
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (!option?.question) {
                            alertError({
                                error: null,
                                customMessage: "Question is required."
                            })
                        }
                        else {
                            onSave(option);
                            setOption(null);
                            close();
                        }
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}