import React, { useEffect, useState } from 'react';
import Table, { buttonsStyle } from "../app/components/tables/table";
import { getDataBlocks } from './structuresCalcs';
import { alertError } from './logger';
import EditPatientTramIntakeDialog from '../app/components/dialogs/userCompetitions/EditPatientTramIntakeDialog';
import { Card, CardBody } from '../_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';
import OnlyText from '../app/components/textFields/OnlyText';
import ConfirmDialog from '../app/components/dialogs/ConfirmDialog';
import { deleteSpecificsTramsXUser, getTramsCompetitionUser } from '../api/tramXuser';
import { useSkeleton } from '../app/hooks/useSkeleton';
import { Edit } from '@material-ui/icons';
import { Button, TextField, Tooltip } from '@material-ui/core';
import MyAutocomplete from '../app/components/MyAutocomplete';
import { getCompetitions } from '../api/competition';
import { getSportFoods } from '../api/sportFood';
import { getMins, formatHourMins, formatFloat, getHours } from './helpers';
import { isLiquid } from './sportFoodUtils';
import OnlyTextMult from '../app/components/textFields/OnlyTextMult';

export function calcCompetitionData(competition) {
    let positiveUnevenness = 0;
    let negativeUnevenness = 0;
    let totalKilometers = 0;

    const trams = competition?.trams || [];
    for (let i = 0; i < trams.length; ++i) {
        const actT = trams[i];
        positiveUnevenness += parseFloat(actT?.positiveUnevenness) || 0;
        negativeUnevenness += parseFloat(actT?.negativeUnevenness) || 0;
        totalKilometers += parseFloat(actT?.longitude) || 0;
    }

    return {
        ...competition,
        positiveUnevenness: positiveUnevenness,
        negativeUnevenness: negativeUnevenness,
        totalKilometers: totalKilometers
    }
}

function sortByNumber(a, b) {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
}

export function calcsFoods(definedTram, time) {
    const foods = definedTram?.foods;

    let choG = 0;
    let sodiumMg = 0;
    let sodiumMgL = 0;
    let waterMl = 0;
    let caffeine = 0;
    
    for (let i = 0; i < foods?.length; ++i) {
        const actF = foods[i];
        const specF = actF.food;
        const ml = actF?.ml || 0;
        const q = actF?.quantity || 1;
        const w = specF.weight || 1;
        if (isLiquid(specF)) {
            waterMl += specF?.water * ml / w || 0;
            choG += specF?.carbohydrates * ml / w;
            sodiumMg += specF?.sodium * ml / w;
            caffeine += specF?.caffeine * ml / w;
        }
        waterMl += specF?.water * q;
        choG += specF?.carbohydrates * q;
        sodiumMg += specF?.sodium * q;
        caffeine += specF?.caffeine * q;
    }

    let choGH = 0, waterMlH = 0;
    if (time) {
        const hours = time / 60;
        choGH = (choG / hours); 
        waterMlH = (waterMl / hours);
        sodiumMgL = sodiumMg / (waterMl/1000 || 1);
    }
    
    return { waterMl, waterMlH, choG, choGH, sodiumMg, sodiumMgL, caffeine }
}

function calcSweatRate(competition) {
    let sweatR = 0;
    for (let i = 1; i <= 3; ++i) sweatR += parseFloat(competition[`sweatRate${i}`] || 0);
    return sweatR;
}

function calcHidrics(patient, competition) {
    let tbwPost = 0, sweatL = 0, hydrationRate = 0;
    const dh = parseFloat(competition?.dh)/100 || 0; 
    const weight = patient?.actualWeight;
    const time = parseFloat(getHours(competition?.totalTime)) || 1;
    const sRate = calcSweatRate(competition);

    const bwLoss = weight * dh;
    tbwPost = weight - bwLoss;

    sweatL = sRate * time;

    const waterReq = (sweatL - bwLoss) * 1000;
    hydrationRate = waterReq / time;

    return { tbwPost, sweatL, hydrationRate, waterReq };
}

function calcAvgPace(time, kms) {
    if (!time || !kms) return '';
    return (getMins(time) / parseFloat(kms));
}

function calcArrival(totalTime, startTime) {
    if (!totalTime || !startTime) return '';
    const totalMins = getMins(totalTime);
    const startMins = getMins(startTime);
    const sum = totalMins + startMins;
    return formatHourMins(sum);
}

export function calcTimeBtwTrams(tram, index, tramsXuser, competition) {
    const actTram = tram;
    const minsAct = getMins(actTram?.time);
    let minsNext = 0;
    if (index + 1 < tramsXuser.length) {
        const nextTram = tramsXuser.find((x) => parseFloat(x?.numTram) === (parseFloat(actTram?.numTram) + 1));
        minsNext = getMins(nextTram?.time);
        if (minsNext < minsAct) minsNext += 24 * 60;
        return minsNext - minsAct; 
    }
    minsNext = competition?.arrivalTime 
        ? getMins(competition?.arrivalTime) 
        : getMins(calcArrival(competition?.totalTime, competition?.departTime));
        
    if (minsNext < minsAct) minsNext += 24 * 60;
    return minsNext - minsAct;
}

export const InfoPatientCompetition = (props) => {
    const { 
        userId, openEditTramUserDialog, setOpenEditTramUserDialog,
        competition, setCompetition, competitions,
        tramsXuser, setTramsXuser, date, patient, inDiet
     } = props;

    const [refresh, setRefresh] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
	const [selectedCompetition, setSelectedCompetition] = useState(null);
    const [competitionOptions, setCompetitionOptions] = useState([]);
    const [completeCompetition, setCompleteCompetition] = useState(null);
    const [allCompetitions, setAllCompetitions] = useState([]);
    const [selectedTram, setSelectedTram] = useState(null);
    const [foods, setFoods] = useState([]);
    const [foodOptions, setFoodOptions] = useState([]);
    const [nutritionals, setNutritionals] = useState(null);
    const [columnsInfo, setColumnsInfo] = useState([]);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    function getEmptyTram() {
        return {
            _id: undefined,
            numTram: '',
            name: '',
            idClient: userId,
            idCompetition: competition?.competitionId,
            foods: [],
            extrInfo: ''
        };
    }

    useEffect(() => {setRefresh(false);}, [refresh]);

    useEffect(() => {
        if (competitions?.length !== 0) actualizeCompetitionOptions(competitions)
    }, [competitions])

    useEffect(() => {
        getCompetitions()
            .then((res) => {
                const complete = res.data.find((x) => x._id === competition.competitionId);
                setCompleteCompetition(complete);
                refreshNutritionals(complete);
                setAllCompetitions(res.data);
                disableLoadingData();
            })
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage: "Could not get all the information about the competition"
                })
            })

    	if (foods?.length === 0) {
			getSportFoods()
				.then((res) => {
					if (res.status === 200) {
						setFoods(res.data);
						actualizeFoodOptions(res.data);
                        if (inDiet) disableLoadingData();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get foods.",
					});
				});
		}
	
    }, [])

    useEffect(() => {
        refreshNutritionals(completeCompetition);
    }, [competition, tramsXuser])

    function refreshNutritionals(completeCompetition) {
        let totals = { waterMl: 0, waterMlH: 0, choG: 0, choGH: 0, sodiumMg: 0, sodiumMgL: 0, caffeine: 0};
        const comp = competitions.find((x) => x._id === competition.competitionId)
        const trams = comp?.trams;
        
        function sumNt(element, calcs, elem) {
            elem[element] = calcs[element];
            totals[element] += calcs[element];
        }

        function sortByNumber(a, b) {
            if (a.number > b.number) return 1;
            if (a.number < b.number) return -1;
            return 0;
        }

        let data = [];

        for (let i = 0; i < trams?.length; ++i) {
            let elem = {};
            const act = trams[i];
            const index = tramsXuser?.findIndex((x) => parseInt(x.numTram) === act.number)
            const definedTram = tramsXuser[index]
            
            elem.name = act.name;
            elem.number = act.number;
			elem.time = definedTram?.time;
            elem.longitude = act?.longitude;
            elem.positiveUnevenness = act?.positiveUnevenness;
            elem.negativeUnevenness = act?.negativeUnevenness;
            elem.foods = definedTram?.foods;
            
            const time = calcTimeBtwTrams(tramsXuser[index], index, tramsXuser, competition);
            const calcs = calcsFoods(definedTram, parseFloat(time || 1));

            sumNt("waterMl", calcs, elem);
            sumNt("choG", calcs, elem);
            sumNt("sodiumMg", calcs, elem);
            sumNt("caffeine", calcs, elem);
            elem.choGH = calcs.choGH;
            elem.waterMlH = calcs.waterMlH;
            elem.sodiumMgL = calcs.sodiumMgL;

            data.push(elem);
        }

        data.sort(sortByNumber)

        const { tbwPost, sweatL, hydrationRate, waterReq } = calcHidrics(patient, competition);
        const totalTime = competition?.totalTime ? getHours(competition.totalTime) : 1;
        const L = totals.waterMl / 1000;
        setNutritionals({
            ...totals,
            choGH: totals.choG / totalTime,
            waterMlH: totals.waterMl / totalTime,
            sodiumMgL: totals.sodiumMg / L,

            //Hidrics
            tbwPost,
            sweatL,
            hydrationRate,
            waterReq
        });
        setColumnsInfo(data);
    }

	function actualizeFoodOptions(foods) {
		let data = [];

		for (let i = 0; i < foods?.length; ++i) {
			const actF = foods[i];
			data.push({
                ...actF,
                name: actF?.fullName?.es
            })
		}
		setFoodOptions(data);
	}

    const statusOptions = ["En espera dels temps de pas", "Preparada per planificar", 
        "En procés de planificació", "Planificada", "Realitzada"];

    function getTramsTableData(tramsXuser) {
    }

    const numberFormatWidth = { width: '96px' };
    const tramsTable = [
        {
            dataField: "name",
            text: "title",
            headerStyle: { width: '200px' }
        },
		{ 
			dataField: "longitude", 
			text: "Distance (km)",
			formatter: fixedZero,
			headerAlign: "center",
			align: "center",
            headerStyle: numberFormatWidth
		},
		{ 
			dataField: "positiveUnevenness", 
			text: "Elevation gain (m)",
			formatter: fixedZero,
			headerAlign: "center",
			align: "center",
            headerStyle: numberFormatWidth
		},
        { 
			dataField: "negativeUnevenness", 
			text: "Elevation loss (m)",
			formatter: fixedZero,
			headerAlign: "center",
			align: "center",
            headerStyle: numberFormatWidth
		},
        { 
			dataField: "time", 
			text: "time",
			// formatter: unevennessFormater,
			headerAlign: "center",
			align: "center",
            headerStyle: numberFormatWidth
		},
        {
            dataField: "choGH",
            text: "CHO (g/h)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero,
            headerStyle: numberFormatWidth
        },
        {
            dataField: "waterMlH",
            text: "water (ml/h)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero,
            headerStyle: numberFormatWidth
        },
        {
            dataField: "sodiumMgL",
            text: "na (mg/l)",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero,
            headerStyle: numberFormatWidth
        },
        {
            dataField: "caffeine",
            text: "caf",
            align: 'center',
            headerAlign: "center",
            formatter: fixedZero,
            headerStyle: numberFormatWidth
        },
        {
            dataField: "number",
            text: "Intake",
            formatter: intakesFormatter,
            align: "left",
            headerAlign: 'left'
        },
        {
            dataField: "number",
            text: "",
            formatter: tramsButtonFormatter,
            align: "right",
            headerStyle: { width: '80px' }
        }
    ]

    function fixedZero(cell) {return parseFloat(cell).toFixed(0);}

    function intakesFormatter(cell, row) {
        let text = ``;
        const foods = row?.foods;
        for (let i = 0; i < foods?.length; ++i) {
            const actF = foods[i];
            text += `${(i !== 0 ? " // " : "")}`
            if (isLiquid(actF?.food)) text += `${actF?.ml || 0} mL`;
            else text += `${actF?.quantity || 0} ud`;
            text += ` ${actF?.food?.fullName?.es}`
        }
        return text;
    }

    function foodsFormatter(cell) {
        let text = "no intake";

        if (cell?.length > 0) {
            text = cell[0].name;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.name;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function observationsFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].observations;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.observations;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

	function tramsButtonFormatter(cell, row) {
        let index = tramsXuser?.findIndex((x => parseFloat(x.numTram) === parseFloat(cell)));
        let tram = tramsXuser[index];
		if (!tram) tram = {...getEmptyTram(), name: row.name, numTram: row.number};

        return (
			<>
                <Tooltip title="Edit tram info">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setOpenEditTramUserDialog(true);
                            setSelectedTram({...tram, ...row});
                        }}
                    >
                        <Edit />
                    </Button>
                </Tooltip>
            </>
		)
	}
    
    function actualizeCompetitionOptions(competitions) {
        let data = [];

		data.push({
			competitionId: -1,
			competitionName: "None"
		})

        for (let i = 0; i < competitions?.length; ++i) {
            let elem = {};
            const act = competitions[i];

            elem.competitionId = act._id;
            elem.competitionName = act.fullName.es;

            data.push(elem);
        }

        setCompetitionOptions(data);
    }

    function setSalida(trams, competition, completeCompetition) {
        let newTrams = [...trams], encontrado = false;
        for (let i = 0; i < newTrams?.length; ++i) {
            const actTram = newTrams[i];
            if (actTram?.numTram === 0) {encontrado = true; break;}
        }
        
        if (!encontrado) newTrams.push({
            numTram: 0,
            idClient: userId,
            idCompetition: competition?.competitionId,
            foods: [],
            time: completeCompetition?.departTime,
            date: competition?.date
        })
        return newTrams;
    }

    function actualizeTramsData(competition, completeCompetition) {
        if (userId && (date?.dateStr ? date?.dateStr : true) && competition?.competitionId && competition?.competitionId !== -1) 
			getTramsCompetitionUser(competition?.competitionId, userId, date.dateStr)
				.then((res) => {
					if (res.status === 200) {
                        const newTrams = setSalida(res.data, competition, completeCompetition);
						setTramsXuser(newTrams);
                        console.log(newTrams)
						// disableLoadingData();
					}
				})
				.catch((error) => {
                    const newTrams = setSalida([], competition, completeCompetition);
                    console.log(newTrams)
                    setTramsXuser(newTrams);
					console.log("No hay tramos");
				});
    }

    function actualizeCompetition(selected) {
		if (competition?.competitionId && competition?.competitionId !== -1) { 
			deleteSpecificsTramsXUser(competition?.competitionId, userId, competition.date)
				.then((res) => {
					if (res.status === 204) {

					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "There was a problem actualizing the trams information"
					})
				})
		}
		const newCompetition = {
            ...competition,
			competitionId: selected.competitionId,
			competitionName: selected.competitionName
		}
        console.log(newCompetition, allCompetitions)
        const complete = allCompetitions.find((x) => x._id === newCompetition.competitionId);
        setCompleteCompetition(complete);
        if (userId) actualizeTramsData(newCompetition, complete);
		setCompetition(newCompetition);
		// actualizeTramsData(newCompetition);
		// setChanges(changes+1);
		setRefresh(true)
	}

    const UserFeedback = () => {
        const fb = competition?.userFeedback;

        return (
            <>
                <h4>Feedback</h4>
                <Row>
                    <Col>
                        <TextField
                            id={`feedbackTime`}
                            label="Total time"
                            value={`${fb?.totalTime ? fb.totalTime : "Not defined by the user..."}`}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="time"
                            className="readonly"
                        />
                    </Col>
                    <Col>
                        <TextField
                            id={`gastroentinals`}
                            label="Gut tolerance"
                            value={`${fb?.gastroentinals ? fb.gastroentinals : "Not defined by the user..."}`}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="text"
                            className="readonly"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id={`notes`}
                            label="Notes"
                            value={`${fb?.notes ? fb.notes : "Not defined by the user..."}`}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="text"
                            className="readonly"
                            multiline
                        />
                    </Col>
                </Row>
            </>
        )
    }

    function handleChange(element, value) {
        let avgPace = 0, arrivalTime = 0;
        if (element === "totalTime") {
            avgPace = calcAvgPace(value, completeCompetition?.totalKilometers);
            arrivalTime = calcArrival(value, completeCompetition?.departTime);
        }

        setCompetition({
            ...competition, 
            [element]: value,
            mediumPace: avgPace ? avgPace : competition?.mediumPace,
            arrivalTime: arrivalTime ? arrivalTime : competition?.arrivalTime
        });
        setRefresh(true);
    }

    function ReadOnly(title, value) {
        return (
            <TextField
                id={`${title}`}
                label={title}
                value={formatFloat(nutritionals[value])}
                inputProps={{ readOnly: true }}
                required
                margin="normal"
                variant="standard"
                type="number"
                className="readonly"
            />
        )
    }

    function NutritionalValues() {
        
        return (
            <>
                <br />
                <Row>
                    <Col><h4>Intake</h4></Col>
                </Row>
                <Row>
                    <Col>{ReadOnly("Water (mL)", "waterMl")}</Col>
                    <Col>{ReadOnly("Water (mL/H)", "waterMlH")}</Col>
                    <Col>{ReadOnly("CHO (g)", "choG")}</Col>
                    <Col>{ReadOnly("CHO (g/h)", "choGH")}</Col>
                    <Col>{ReadOnly("Na (mg)", "sodiumMg")}</Col>
                    <Col>{ReadOnly("Na (mg/L)", "sodiumMgL")}</Col>
                    <Col>{ReadOnly("Caf", "caffeine")}</Col>
                </Row>
            </>
        )
    }

    function formatMMSS(value) {
        if (!value) return;
        const fl = parseFloat(value);
        const m = Math.floor(fl);
        const s = Math.floor((fl%1) * 60)
        return `${m}:${s}`;
    }

    if (isLoadingData) return (<ContentSkeleton />)
    return (<>
        <ConfirmDialog
            title={
            "Are you sure you want to change the competition? You will lose all your changes"
            }
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
                actualizeCompetition(selectedCompetition)
                setOpenConfirmDialog(-1);
            }}
        />
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <MyAutocomplete
                            options={ competitionOptions }
                            getOptionLabel={(option) =>
                                option.competitionName
                            }
                            value={ competitionOptions.find(option => option.competitionId === competition?.competitionId) || null }
                            onChange={(event, selected) => {
                                if (!competition?.competitionId || competition?.competitionId === -1) actualizeCompetition(selected);
                                else {
                                    setOpenConfirmDialog(2)
                                    setSelectedCompetition(selected);
                                }
                            }}
                            placeholder="Select competition"
                            label={"Type of competition"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MyAutocomplete 
                            options={ statusOptions }
                            getOptionLabel={(option) =>
                                option
                            }
                            value={ statusOptions.find(option => option === competition?.status) || null }
                            onChange={(event, selected) => {
                                setCompetition({
                                    ...competition,
                                    status: selected
                                })
                            }}
                            placeholder="Select status competition"
                            label={"Status competition"}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <OnlyText title="Total time" element="totalTime" data={competition} specific={false} type="time"
                            handleChange={() => (event) => {
                                handleChange("totalTime", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col sm={4}>
                        { completeCompetition && 
                        <TextField
                            id={`mediumPace`}
                            label="Avg pace (min/km)"
                            value={formatMMSS(competition?.mediumPace)}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="text"
                            className="readonly"
                        />
                        }
                    </Col>
                    <Col sm={4}>
                        { completeCompetition && 
                        <TextField
                            id={`arrivalTime`}
                            label="Arrival time"
                            value={competition?.arrivalTime}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="time"
                            className="readonly"
                        />
                        }
                    </Col>
                </Row>

                <br />
                <Row><Col><h4>Requirements</h4></Col></Row>
                {/* <HidricRequirements /> */}
                <Row>
                    <Col>
                        <OnlyText title="Sweat rate 1 (L/h)" element="sweatRate1" data={competition} specific={false} type="number"
                            handleChange={() => (event) => {
                                handleChange("sweatRate1", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col>
                        <OnlyText title="Time 1" element="time1" data={competition} specific={false} type="time"
                            handleChange={() => (event) => {
                                handleChange("time1", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col>
                        <OnlyText title="Sweat rate 2 (L/h)" element="sweatRate2" data={competition} specific={false} type="number"
                            handleChange={() => (event) => {
                                handleChange("sweatRate2", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col>
                        <OnlyText title="Time 2" element="time2" data={competition} specific={false} type="time"
                            handleChange={() => (event) => {
                                handleChange("time2", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col>
                        <OnlyText title="Sweat rate 3 (L/h)" element="sweatRate3" data={competition} specific={false} type="number"
                            handleChange={() => (event) => {
                                handleChange("sweatRate3", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col>
                        <OnlyText title="Time 3" element="time3" data={competition} specific={false} type="time"
                            handleChange={() => (event) => {
                                handleChange("time3", event.target.value);
                            }}	
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <OnlyText title="%DH" element="dh" data={competition} specific={false} type="number"
                            handleChange={() => (event) => {
                                handleChange("dh", event.target.value);
                            }}	
                        />
                    </Col>
                    <Col>
                        <TextField
                            id={`sweatL`}
                            label="Sweat (L)"
                            value={formatFloat(nutritionals?.sweatL)}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="number"
                            className="readonly"
                        />
                    </Col>
                    <Col>
                        <TextField
                            id={`hydrationRate`}
                            label="Hydration Rate (mL/H)"
                            value={formatFloat(nutritionals?.hydrationRate)}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="number"
                            className="readonly"
                        />
                    </Col>
                    <Col>
                        <TextField
                            id={`waterReq`}
                            label="Total water req (mL)"
                            value={formatFloat(nutritionals?.waterReq || 0).toFixed(0)}
                            inputProps={{ readOnly: true }}
                            required
                            margin="normal"
                            variant="standard"
                            type="number"
                            className="readonly"
                        />
                    </Col>
                </Row>

                <NutritionalValues />
                
                <br />
                <Row>
                    <Col><h4>Sections</h4></Col><Col/><Col/>
                    <Col />
                </Row>
                <br/>
                {!refresh && (
                    <Table 
                        columns={tramsTable}
                        data={columnsInfo}
                    />
                )}
                <br />
                <Row>
                    <Col>
                        <OnlyTextMult title="Comments" element="comments" data={competition} specific={false}
                            handleChange={() => (event) => {
                                handleChange("comments", event.target.value);
                            }}	
                        />
                    </Col>
                </Row>
                <br />
                <UserFeedback />
            </CardBody>
        </Card>

        <EditPatientTramIntakeDialog 
            open={openEditTramUserDialog}
            setOpen={setOpenEditTramUserDialog}
            data={selectedTram}
            userId={userId}
            competitionId={competition?.competitionId}
            competition={competition}
            foods={foods}
            setFoods={setFoods}
            foodOptions={foodOptions}
            setFoodOptions={setFoodOptions}
            tramsXuser={tramsXuser}

            onSave={(intakeTram) => {
                let newTrams = [...tramsXuser];

                const index = tramsXuser?.findIndex((x) => x.numTram === intakeTram.numTram);
                if (index === -1) newTrams.push(intakeTram);
                else newTrams[index] = intakeTram;
                
                setTramsXuser(newTrams);
                setRefresh(true);
            }}
        />
        
    </>)
}

export const tramsTable = [
    {
        dataField: "name",
        text: ""
    },
    {
        dataField: "longitude",
        text: "KM",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "positiveUnevenness",
        text: "D+",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "negativeUnevenness",
        text: "D-",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "time",
        text: "Tiempo",
        headerAlign: "center",
        align: "center"
    },
    {}
]

export function getDataTrams(trams, tramsXuser) {

    let data = [];

    for (let i = 0; i < trams?.length; ++i) {
        let elem = {};
        const actT = trams[i];

        const definedTram = tramsXuser?.find((x) => parseInt(x?.numTram) === actT.number)

        elem.number = actT?.number;
        elem.name = actT?.name;
        elem.longitude = actT?.longitude;
        elem.positiveUnevenness = actT?.positiveUnevenness;
        elem.negativeUnevenness = actT?.negativeUnevenness;
        
        elem.time = definedTram?.time || "0";

        data.push(elem);
    }
    
    data.sort(sortByNumber)
    return data;
}

export const BlocksTable = React.memo((props) => {
    const { blocks, setNt, patient } = props;

    const [dataBlocks, setDataBlocks] = useState([]);

    useEffect(() => {
        if (blocks) getDataB(blocks);
    }, [blocks])

    const blockColumns = [
        {
            dataField: "number",
            text: "type",
            formatter: typeFormatter,
        },
        {
            dataField: "kcals",
            text: "kcal",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "carbohydrates",
            text: "cho",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "proteins",
            text: "pro",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fats",
            text: "fat",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fiber",
            text: "fiber",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
    ];
    
    function numbersFormatter(cell) {return cell.toFixed(1);}
    
    function typeFormatter(cell) {
        const block = blocks.find((x) => cell === x.number);
        return (block?.isIntake? block?.intake.isSnack? "Snack" : "Main" : "Exercise");
    }

    async function getDataB(blocks) {
        const dataBlocks = await getDataBlocks(blocks, patient);
		setDataBlocks(dataBlocks.data);
        delete dataBlocks.data;
        setNt(dataBlocks);
    }

    return (<Table data={dataBlocks} columns={blockColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 4
export const IntakesCompetitionTable = React.memo((props) => {
    const { tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const foodColumns = [
        {
            dataField: "name",
            text: ""
        },
        {
            dataField: "grams",
            align: 'center',
            headerAlign: 'center',
            text: "grams"
        },
        {
            dataField: "kcals",
            text: "kcal",
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "carbohydrates",
            text: "cho",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "proteins",
            text: "pro",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fats",
            text: "fat",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        }
    ];
    
    function numbersFormatter(cell) {return cell.toFixed(1);}

    async function getDataI(tramsXuser) {
        const foodIds = [...new Set(tramsXuser.flatMap(item => item.foods.map(food => food.food)))];
        
        let data = [];

        for (let i = 0; i < foodIds?.length; ++i) {
            let elem = {};
            const food = foodIds[i];
            if (food) {
                elem.name = food.fullName.es;
                elem.kcals = parseFloat(food?.kcals);
                elem.carbohydrates = parseFloat(food?.carbohydrates);
                elem.proteins = parseFloat(food?.proteins);
                elem.fats = parseFloat(food?.fats);
                elem.fiber = parseFloat(food?.fiber);
                elem.grams = 100;
            }

            data.push(elem);
        }

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={foodColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 6
export const NutritionalBag = React.memo((props) => {
    const { trams, tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const tramsColumns = [
        {
            dataField: "quantity",
            text: "quantity",
        },
        {
            dataField: "name",
            text: "name",
            align: 'center',
            headerAlign: "center"
        },
        {
            dataField: "type",
            text: "product",
            align: 'center',
            headerAlign: "center",
            formatter: typeFormatter
        },
    ]
    
    function typeFormatter(cell, row) {
        return cell;
    }

    async function getDataI(tramsXuser) {

        async function getFood(food) {
            return food.food;
        }

        const uniqueFoodsMap = new Map();

        for (const item of tramsXuser) {
            for (const food of item.foods) {
                if (!uniqueFoodsMap.has(food.food)) {
                    const definedFood = await getFood(food);
                    uniqueFoodsMap.set(food.food, { ...food, definedFood });
                } else {
                    const existingFood = uniqueFoodsMap.get(food.food);
                    existingFood.quantity += food.quantity || 0;
                    existingFood.grams += food.grams || 0;
        
                    uniqueFoodsMap.set(food.food, existingFood);
                }
            } 
        }

        let data = [];

        uniqueFoodsMap.forEach((food) => {
            let elem = {};
            
            elem._id = food.food;
            if (food?.selectedPortion >= 0) {
                const defF = food.definedFood;
                const portion = defF?.portions?.find((x) => x._id === food.selectedPortion);
                elem.quantity = `${food.quantity} ${portion.name}`;
            } else elem.quantity = `${food.grams} grams`;
            elem.name = food.name;
            elem.type = food.name;

            data.push(elem);
        })

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={tramsColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 7
export const DistributionIntakesTable = React.memo((props) => {
    const { trams, tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const tramsColumns = [
        {
            dataField: "name",
            text: "title"
        },
        {
            dataField: "foods",
            text: "quantity",
            align: 'center',
            headerAlign: "center",
            formatter: quantityFormatter
        },
        {
            dataField: "foods",
            text: "food",
            align: 'center',
            headerAlign: "center",
            formatter: foodsFormatter
        },
        {
            dataField: "foods",
            text: "observations",
            align: 'center',
            headerAlign: "center",
            formatter: observationsFormatter
        },
    ]

    function quantityFormatter(cell, row) {

        function getFoodInfoText(food, definedFoods) {
            if (food?.selectedPortion >= 0) {
                const actDF = definedFoods?.find((x) => x._id === cell[0].food)
                const quantityName = actDF?.portions?.find((x) => x._id === cell[0].selectedPortion)
                return cell[0].quantity + " " + quantityName.name;
            } else return cell[0].grams + " grams";
        }
        let text = "";
        const definedFoods = row?.definedFoods;

        if (cell?.length > 0) {
            text += getFoodInfoText(cell[0], definedFoods);
            for (let i = 1; i < cell?.length; ++i) text += "<br>" + getFoodInfoText(cell[i], definedFoods);
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function foodsFormatter(cell) {
        let text = "no intake";

        if (cell?.length > 0) {
            text = cell[0].name;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.name;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function observationsFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].observations;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.observations;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    async function getFoods(foods) {
        let data = [];
        for (let i = 0; i < foods?.length; ++i) {
            data.push(foods[i].food)
        }
        return data;
    }

    async function getDataI(tramsXuser) {
        let data = [];

        for (let i = 0; i < trams?.length; ++i) {
            let elem = {};
            const act = trams[i];
            const definedTram = tramsXuser?.find((x) => parseInt(x.numTram) === act.number)
            const definedFoods = await getFoods(definedTram?.foods);
            
            elem.name = act.name;
            elem.number = act.number;
            elem.foods = definedTram?.foods || [];
            elem.definedFoods = definedFoods || [];

            data.push(elem);
        }

        data.sort(sortByNumber)

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={tramsColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})