import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import VerificationPage from "./VerificationPage";
import VerificationSuccessPage from "./VerificationSuccessPage";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Verification() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          {/* Contenedor separado para el logo */}
          <div style={{ textAlign: 'center', marginTop: '50px', width: '100%' }}>
            <img 
              src={toAbsoluteUrl("/media/logo/logo-black.png")}
              style={{ maxWidth: '200px' }}
              alt="Logo"
            />
          </div>

          {/* Contenido restante de la página */}
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <Switch>
              <ContentRoute path="/verify-email" component={VerificationPage} />
              <ContentRoute path="/verify-email-success" component={VerificationSuccessPage} />
              <Redirect to="/verify-email" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}