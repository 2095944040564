import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { checkIsEmpty, formatFloat } from "../../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../../utils/logger";
import MultilanguageTabBlock from "../../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import EditStructureBlockDialog from "../../../../components/dialogs/structures/EditStructureBlockDialog";
import Table, {
	buttonsStyle,
} from "../../../../components/tables/table";
import { useSkeleton } from "../../../../hooks/useSkeleton";
import { Row, Col } from 'react-bootstrap';
import Editor from "../../../../components/editor/Editor";
import { blockCalc, blockIsMain, calcUnitsNt, deleteAllBlocksRecipesFromBD, 
	deleteBlockRecipesFromBD, descriptionFormatter, getDataBlocksTable, 
	styleFormat, calcExerciseMinutes, 
	calcRequeriments} from "../../../../../utils/structuresCalcs";
import { deleteUserStructures, getUserStructuresById, postUserStructures, updateUserStructures } from "../../../../../api/userStructures";
import MyAutocomplete from "../../../../components/MyAutocomplete";
import { getStructureById, getStructures } from "../../../../../api/structure";
import { postFoodPlateStructure, updateFoodPlateStructure } from "../../../../../api/foodPlateStructure";
import _ from 'lodash';
import { calcTEEDay, calcTEERKcalh } from "../../../../../utils/patientCalcs";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export default function EditPatientStructure(props) {
    const { structure, setStructure, setOpen, userId, patient, number, getNumberStructures } = props;
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [openEditBlockDialog, setOpenEditBlockDialog] = useState(false);
	const [openViewBlockDialog, setOpenViewBlockDialog] = useState(false);
	const [isMeal, setIsMeal] = useState(false);
	const [blocks, setBlocks] = useState([]);
	const [selectedBlock, setSelectedBlock] = useState(null);

    const [structures, setStructures] = useState([]);
    const [structuresOptions, setStructuresOptions] = useState([]);
    const [structureHasChanged, setStructureHasChanged] = useState(false);
    const [selectedStructure, setSelectedStructure] = useState(null);
    const [dontShowEditor, setDontShowEditor] = useState(false);
	const [selectedDeleteBlock, setSelectedDeleteBlock] = useState(-1);

	const [refresh, setRefresh] = useState(false);
	const [changes, setChanges] = useState(-2);

	const [selectedNtOption, setSelectedNtOption] = useState({_id: 3, value: "g"});

    function getEmptyStructure() {
        return {
            userId: userId,
            fullName: {},
            description: {},
            kcal: 0,
            carbohydrates: 0,
            protein: 0,
            fats: 0,
            fatskg: 0,
            fatspercent: 0,
            blocks: [],
            imageURL: null,
            active: true,
        };
    }

	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    useEffect(() => {
        if (structure) {
            let structureData = structure;

            if (structureData?.block?.length > 0) {
                for (
                    let i = 0;
                    i < structureData?.block?.length;
                    ++i
                ) {
                    structureData.block[i].id = i;
                }
            }
            reCalcNutritionalValues({...structure});
            setBlocks(structure?.blocks);
        }
        else {
            reCalcNutritionalValues(getEmptyStructure());
        }

        if (structures?.length === 0)
            getStructures()
                .then((res) => {
                    if (res.status === 200) {
                        setStructures(res.data);
                        actualizeStructuresOptions(res.data);
                    }
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get structures.",
                    });
                });
        disableLoadingData();
    }, []);

	const ntOptions = [
		{ _id: 3, value: "g"},
		{ _id: 1, value: "g/kg"},
		{ _id: 2, value: "%"},
	];

    function actualizeStructuresOptions(structures) {
		let data = [];

		for (let i = 0; i < structures?.length; ++i) {
			let elem = {}
			const act = structures[i];

			elem._id = act._id;
			elem.name = act.fullName.es;

			data.push(elem);
		}

		setStructuresOptions(data);
	}

	function typeFormatter(cell) {
		const block = blocks.find((x) => cell === x.number);
		return (block?.isIntake? (!block?.intake.isSnack || block?.intake?.isSnack === '0')? "Main" : "Snack" : "Exercise");
	}
	
	function blockMove(block, index, newIndex) {
		let blocks = [
			...structure?.blocks
		]
		const blockUp = blocks.find(
			(x) => x.number === newIndex
		)
		
		blocks[newIndex] = {
			...block,
			number: newIndex
		};
		blocks[index] = {
			...blockUp,
			number: index
		}

		reCalcNutritionalValues({
			...structure,
			blocks: blocks
		});
		setBlocks(blocks);
		setRefresh(true);
	}

	async function openData(block) {
		setSelectedBlock(block);
	}

	useEffect(() => {
		if (selectedBlock) setOpenEditBlockDialog(true);
	}, [selectedBlock])

	useEffect(() => {
		if (!openEditBlockDialog) setSelectedBlock(null);
	}, [openEditBlockDialog])
	
	function blocksButtonFormatter(cell) {
		const index = blocks.findIndex((x) => x.number === cell);
		const block = blocks[index];

		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							openData(block);
							// setOpenEditBlockDialog(true);
							// setSelectedBlock(block);
							setIsMeal(block.isIntake);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => blockMove(block, index, index-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							index >= structure?.blocks?.length - 1
						}
						style={buttonsStyle}
						onClick={() => blockMove(block, index, index+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenConfirmDialog(4)
							setSelectedDeleteBlock(index);
							// let newBlocks = [...blocks];
							// newBlocks.splice(index, 1);

                            // for (let i = index; i < newBlocks?.length; ++i) newBlocks[i].number = newBlocks[i].number - 1;

							// reCalcNutritionalValues({
							// 	...structure,
							// 	blocks: [...newBlocks]
							// });
							// setBlocks([...newBlocks]);
							// setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		)
	}

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

    useEffect(() => {
        if (structure) {
            setDontShowEditor(false);
            setStructureHasChanged(true);
            if (changes < 1) setChanges(changes+1);
        }
    }, [structure])

	async function deleteBlock(index) {
		const res = await deleteBlockRecipesFromBD(structure, index);
		if (res) {
			let newBlocks = [...blocks];
			newBlocks.splice(index, 1);

			for (let i = index; i < newBlocks?.length; ++i) newBlocks[i].number = newBlocks[i].number - 1;

			const newStructure = await reCalcNutritionalValues({
				...structure,
				blocks: [...newBlocks]
			});
			setBlocks([...newBlocks]);
			setSelectedDeleteBlock(-1);
			if (newStructure?._id) refreshStructure(newStructure);
			setRefresh(true);
		}
	}

	async function refreshStructure(newStructure) {
		await updateSt(newStructure);
		const data = await getUserStructuresById(newStructure._id)
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get user structures information, please recharge the page."
				})
			});
		const saveStructure = data?.data || newStructure;
		setChanges(-1);
		reCalcNutritionalValues(saveStructure)
		setBlocks(saveStructure?.blocks);
	}

	async function saveRecipe(recipe) {
		if (recipe?._id) {
			const res = await updateFoodPlateStructure(recipe?._id, recipe)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update recipe."
					})
					return null;
				})
			if (res) return recipe?._id;
		}
		else {
			delete recipe._id
			const res = await postFoodPlateStructure(recipe)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save recipe."
					})
					return null;
				})
				
			if (res) return res.data._id;
		}

		return null;
	}

	async function updateSt(structure) {
		for (let i = 0; i < structure?.blocks?.length; ++i) {
			let actB = {...structure?.blocks[i]};
			if (actB?.isIntake) {
				if (actB?.intake?.recipes?.length > 0) {
					let recipes = [...actB?.intake?.recipes];
					for (let j = 0; j < recipes?.length; ++j) {
						let actR = {...actB?.intake?.recipes[j].recipe};
						actR.structure = structure?._id;
						const res = await saveRecipe(actR);
						if (!res) return false;
						actR = res;
						recipes[j].recipe = actR;
						console.log(actR)
					}
					actB = {
						...actB, 
						intake: {
							...actB?.intake, 
							recipes: recipes 
						}
					}
					structure.blocks[i] = actB;
				}
			}
		}

		const res = await updateUserStructures(structure?._id, structure, null)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Saved!",
						customMessage: "Changes successfully saved.",
					});
					setChanges(0);
					return true;
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not save changes.",
				});
				return false;
			});
		if (res) return true;
		return false;
	}

	async function saveStructure(close) {

		async function postStruct(structure) {
			const res = await postUserStructures(structure, null)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save structure.",
					});
					return null;
				});
			return res;
		}

		let saveStructure = {...structure};
		if (saveStructure?.number === undefined) saveStructure.number = number;

		if (saveStructure?.block?.length > 0) {
			saveStructure.block.forEach((block) => {
				if (block?.id) delete block.id;
			});
		}

		if (!saveStructure?._id) {
			let auxStructure = {...structure}
			if (auxStructure?.number === undefined) auxStructure.number = number;
			delete auxStructure.blocks;
			const res = await postStruct(auxStructure);
			if (!res) return false;
			saveStructure._id = res.data._id;
		}

		if (checkIsEmpty(structure?.fullName)) {
			alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
			return false;
		}

		if (saveStructure?._id) {
			const res = await updateSt(_.cloneDeep(saveStructure));
			if (res) { if (close) onClose()}
			else return false;
		}

		return true;
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!structure[element]) structure[element] = {};
			let newText = structure[element];
			newText[lang] = event.target.value;
			setStructure({ ...structure, [element]: newText });
		} else
			setStructure({
				...structure,
				[element]: event.target.value,
			});
	};

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
			if (value === " ") return;
			if (!structure[element]) structure[element] = {};
			let newText = structure[element];
			newText[lang] = value;
			setStructure({ ...structure, [element]: newText });
		} else setStructure({ ...structure, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(structure?.fullName && structure?.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
				<br />
				{!dontShowEditor && (<Editor
					body={structure?.description?.es || ""}
					setBody={(new_body) =>
						handleChangeEditor("description", lang, new_body)
					}
					className="max-height"
					lang={lang}
					placeholder={"Enter structure description here..."}
					name="Description"
				/>)}
			</>
		);
	};

	function titleFormatter(cell, row) {
		const blocks = structure?.blocks;
		const blockIndex = structure?.blocks.findIndex((x) => cell === x.number);
		const block = blocks[blockIndex];
		const name = block?.name;
		if (row?.type === "Snack") {
			let sumDown = 0, sumUp = 0;
			for (let i = blockIndex-1; i >= 0; i--) { 
				const actB = blocks[i];	
				if (actB?.name === name && actB?.intake?.isSnack) sumDown++; 
				else break;
			}
			for (let i = blockIndex+1; i < blocks.length; i++) { 
				const actB = blocks[i];	
				if (actB?.name === name && actB?.intake?.isSnack) sumUp++; 
				else break;
			}
			if ((sumDown + sumUp) > 0) {
				if (sumUp === 0 && sumDown%3 === 0) return name;
				return `${block.name} (Option ${(sumDown%3)+1})`;
			}
		}
		return name;
	}

	const numbersHeaderStlye = { width: '71px' };
	
	const blockColumns = [
		{
			dataField: "number",
			text: "title",
			style: styleFormatter,
			headerStyle: { width: '180px' },
			formatter: titleFormatter
		},
		{
			dataField: "description",
			text: "description",
			formatter: descriptionFormatter,
			style: styleFormatter,
		},
		{
			dataField: "kcals",
			text: "kcal",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			style: styleFormatter,
			formatter: formatFloat
		},
		{
			dataField: "carbohydrates",
			text: "cho",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			style: styleFormatter,
			formatter: formatFloat
		},
		{
			dataField: "carbohydratesG",
			text: "cho (g/kg)",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			style: (cell, row) => ({
				fontWeight: 'bold',
				...styleFormatter(cell, row),
			}),
			formatter: formatFloat
		},
		{
			dataField: "proteins",
			text: "pro",
			headerAlign: 'center',
			style: styleFormatter,
			align: 'center',
			headerStyle: numbersHeaderStlye,
			formatter: formatFloat
		},
		{
			dataField: "proteinsG",
			text: "pro (g/kg)",
			headerAlign: 'center',
			style: (cell, row) => ({
				fontWeight: 'bold',
				...styleFormatter(cell, row),
			}),
			align: 'center',
			headerStyle: numbersHeaderStlye,
			formatter: formatFloat
		},
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			style: styleFormatter,
			formatter: formatFloat
		},
		{
			dataField: "fiber",
			text: "fiber",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStlye,
			style: styleFormatter,
			formatter: formatFloat,
		},
		{
			dataField: "number",
			text: "",
			formatter: blocksButtonFormatter,
			style: styleFormatter,
			align: 'right',
			headerStyle: { width: '190px'}
		}
	];

	function styleFormatter(cell, row) {
		const block = blocks?.find((x) => x.number === row.number);
		return styleFormat(block);
	}

    function onClose() {
        setOpen(false);
        setStructure(null);
        setBlocks(null);
    }

	async function reCalcNutritionalValues(structure) {
		let blocks = [...structure?.blocks] || [];
		let kcals = 0;
		let carbohydrates = 0;
		let protein = 0;
		let fats = 0;
		let fiber = 0;
		let energetics = 0;
		let requeriments = 0;
		let totalExerciseTime = 0, totalBurnedKcals = 0, totalBurnedFats = 0;

		for (let i = 0; i < blocks.length; ++i) {
			let block = blocks[i];
			const calcs = await blockCalc(block, patient);

			block.kcals = calcs?.kcals;
			block.carbohydrates = calcs?.carbohydrates;
			block.proteins = calcs?.proteins;
			block.fats = calcs?.fats;
			block.fiber = calcs?.fiber;

			const blockForw = (i+1 !== blocks?.length) ? blocks[i+1] : null;
			if (block?.isIntake) {
				if (block?.name === blockForw?.name && !blockIsMain(block) && !blockIsMain(blockForw)) {
					let kcalsSum = block?.kcals;
					let carbohydratesSum = block?.carbohydrates;
					let proteinSum = block?.proteins;
					let fatsSum = block?.fats;
					let fiberSum = block?.fiber;
					let counter = 1;
	
					const name = block.name;
					for (let j = i+1; j < blocks?.length; ++j) {
						let actBlock = blocks[j];
						if (actBlock?.name === name && !blockIsMain(actBlock) && !blockIsMain(actBlock)) {
							const calcsAct = await blockCalc(actBlock, patient);
							
							actBlock.kcals = calcsAct?.kcals;
							actBlock.carbohydrates = calcsAct?.carbohydrates;
							actBlock.proteins = calcsAct?.proteins;
							actBlock.fats = calcsAct?.fats;
							actBlock.fiber = calcsAct?.fiber;

							kcalsSum += calcsAct.kcals;
							carbohydratesSum += calcsAct.carbohydrates;
							proteinSum += calcsAct.proteins;
							fatsSum += calcsAct.fats;
							fiberSum += calcsAct.fiber;
							counter++;
						} else break;
					}
					
					kcals += kcalsSum/counter;
					carbohydrates += carbohydratesSum/counter;
					protein += proteinSum/counter;
					fats += fatsSum/counter;
					fiber += fiberSum/counter;
					i += counter-1;
				} else {
					kcals += calcs?.kcals;
					carbohydrates += calcs?.carbohydrates;
					protein += calcs?.proteins;
					fats += calcs?.fats;
					fiber += calcs?.fiber;
				}
			} else {
				kcals = kcals - calcs?.kcals;
				totalBurnedKcals += parseFloat(calcs?.kcals);
				totalBurnedFats += parseFloat(calcs?.fats);
				totalExerciseTime += calcExerciseMinutes(block);
			}
		}

		const teekcalh = calcTEERKcalh(patient?.teeRest || 0);

		requeriments = calcTEEDay(
			teekcalh || 0, 
			(totalExerciseTime || 0) / 60, 
			totalBurnedKcals || 0
		)
		
		const reqs = calcRequeriments(requeriments, patient, totalExerciseTime, totalBurnedFats)
		// console.log(reqs)

		const newStructure = {
			...structure,
			kcal: kcals,
			carbohydrates: carbohydrates,
			protein: protein,
			fats: fats,
			fiber: fiber,
			energetics: energetics,
			requeriments: requeriments,
			reqCho: reqs.reqCho,
			reqPro: reqs.reqPro,
			reqFat: reqs.reqFat
		};
		setStructure(newStructure);
		setRefresh(true);
		return {...newStructure}; 
	}

    async function changeStructure(structureId) {
		const res = await deleteAllBlocksRecipesFromBD(structure);
		if (!res) return;
        getStructureById(structureId)
			.then((res) => {
				if (res.status === 200) {
					let structureData = {...res.data};

					let blocks = [...structureData?.blocks];
					if (blocks?.length > 0) {
						for (let i = 0; i < blocks?.length; ++i) {
							let block = blocks[i];
							let recipes = block?.intake?.recipes;
							if (recipes?.length > 0) {
								// structureData.block[i].id = i;
								for (let j = 0; j < recipes?.length; ++j) {
									let actR = recipes[j];
									delete actR.recipe._id;
									actR.recipe.typeStructure = "userStructure"
									recipes[j] = actR;
								}
								block.recipes = recipes;
							}
							blocks[i] = block;
						}
					}

					structureData.blocks = blocks;
					if (!structure?._id) {
						setBlocks(structureData?.blocks);
						reCalcNutritionalValues({...structureData, _id: structure?._id || null, userId: userId});
					}
					else refreshStructure(structureData);
                    setRefresh(true);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get structure.",
				});
			});
    }

	async function actualizeStructure(block) {
		const index = structure?.blocks?.findIndex((x) => x.number === selectedBlock?.number)
		let newBlocks = [...structure?.blocks];
		newBlocks[index] = block;
		const newStructure = {
			...structure,
			blocks: newBlocks
		}

		const res = await updateSt(newStructure);
		if (res) {setStructure({...newStructure}); setBlocks(newStructure?.blocks); setRefresh(true);}
		return res;
	}

	async function deleteStructure(structure) {
		const res = await deleteAllBlocksRecipesFromBD(structure);
		if (!res) return;
		deleteUserStructures(structure?._id)
			.then((res) => {
				if (res.status === 204 || res.status === 200) {
					alertSuccess({
						title: "Deleted!",
						customMessage:
							"Structure deleted successfully",
					});
					onClose();
					setStructure(null);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						"Could not delete structure?.",
				});
			});
	}

	async function saveAndCopy() {
		const res = await saveStructure();
		if (res) {
			let newStructure = {...structure};
			let blocks = newStructure.blocks;
			for (let i = 0; i < blocks.length; ++i) {
				let actB = blocks[i];
				if (actB.isIntake) {
					let recipes = actB?.intake?.recipes;
					if (recipes.length > 0) {
						for (let j = 0; j < recipes.length; ++j) {
							recipes[j].recipe._id = null;
						}
					}
				}
			}

			setStructure({
				...newStructure,
				_id: null,
				number: getNumberStructures(),
				fullName: {
					es: `${structure.fullName.es} - Copy`
				}
			})
		}
	}

	function reqFormat(req, type) {
		if (!req) return "";
		const min = parseFloat(calcUnitsNt(
			req.min, selectedNtOption.value, type, 
			structure?.requeriments, 
			patient?.selectedWeight || 1
		))
		const max = parseFloat(calcUnitsNt(
			req.max, selectedNtOption.value, type, 
			structure?.requeriments, 
			patient?.selectedWeight || 1
		))
		const fixed = selectedNtOption.value !== "g/kg" ? 0 : 1;
		return `${min.toFixed(fixed)} - ${max.toFixed(fixed)}`;
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={structure?._id?'Structure: ' + (structure?.fullName?structure?.fullName.es:''):'New structure'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else onClose();
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						{ structure?._id && (
							<Button
								onClick={() => {
									if (changes > 0) setOpenConfirmDialog(5);
									else saveAndCopy();
								}}
								variant="outlined"
								style={{ marginRight: "20px" }}
							>
								Copy structure
							</Button>
						)}
						<Button
							onClick={() => saveStructure(true)}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save structure
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
                                onClose();
							}}
						/>
                        <ConfirmDialog
                            title={
                            "Are you sure you want to change the structure? You will lose all your changes"
                            }
                            open={openConfirmDialog === 3}
                            setOpen={setOpenConfirmDialog}
                            onConfirm={() => {
                                setDontShowEditor(true);
                                changeStructure(selectedStructure?.newSelected?._id);
                                setSelectedStructure({...selectedStructure.newSelected})
                                setRefresh(true);
                                setOpenConfirmDialog(-1);
                            }}
                        />
						<ConfirmDialog
							title={
								"Are you sure you want to delete the block? You will lose all your block information"
							}
							open={openConfirmDialog === 4}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteBlock(selectedDeleteBlock)
							}}
						/>
						<ConfirmDialog
							title={
								"Are you sure you want to copy the structure? You will save all your changes"
							}
							open={openConfirmDialog === 5}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								saveAndCopy();
							}}
						/>
						{structure?._id && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete structure
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this structure?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteStructure(structure);	
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						{ !structure?._id && 
						(
							<>
								<MyAutocomplete
									options={ structuresOptions }
									getOptionLabel={(option) =>
										option.name
									}
									value={ structuresOptions.find(option => option._id === selectedStructure?._id) || null }
									onChange={(event, selected) => {
										if (structureHasChanged && structure?.blocks?.length !== 0 && structure) {
											setOpenConfirmDialog(3);
											setSelectedStructure({...selectedStructure, newSelected: selected})
										} else {
											setDontShowEditor(true);
											changeStructure(selected._id);
											setRefresh(true);
											setSelectedStructure(selected);
										}
									}}
									placeholder="Select structure"
									label={"Type of structure"}
								/>
								<br />
							</>
						)
						}
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<Row>
							<Col>
								<MyAutocomplete
									options={ ntOptions }
									getOptionLabel={(option) =>
										option.value
									}
									value={ ntOptions.find((option) => option._id === selectedNtOption?._id) || null }
									onChange={(event, selected) => {
										setSelectedNtOption(selected);
									}}
									placeholder="Select units"
									label={"Type of units"}
								/>
							</Col>
						</Row>
						<Row>
							<Col >
								<TextField
									id={`requerimentsKcals`}
									label={`Requirements KCAL`}
									value={structure?.requeriments}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col >
								<TextField
									id={`carbohydrates`}
									label={`Requirements CHO (${selectedNtOption.value})`}
									value={reqFormat(structure?.reqCho, "carbohydrates")}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="text"
									className='readonly'
								/>
							</Col>
							<Col >
								<TextField
									id={`protein`}
									label={`Requirements PRO (${selectedNtOption.value})`}
									value={reqFormat(structure?.reqPro, "proteins")}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="text"
									className='readonly'
								/>
							</Col>
							<Col >
								<TextField
									id={`fats`}
									label={`Requirements FAT (${selectedNtOption.value})`}
									value={reqFormat(structure?.reqFat, "fats")}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="text"
									className='readonly'
								/>
							</Col>
							<Col />
						</Row>
						<Row>
							<Col>
								<TextField
									id={`kcal`}
									label="KCAL"
									value={formatFloat(structure?.kcal)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col >
								<TextField
									id={`carbohydrates`}
									label={`CHO (${selectedNtOption.value})`}
									value={calcUnitsNt(
										structure?.carbohydrates, 
										selectedNtOption.value, 
										"carbohydrates", 
										structure?.kcal, 
										patient?.selectedWeight || 1
									)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="text"
									className='readonly'
								/>
							</Col>
							<Col >
								<TextField
									id={`protein`}
									label={`PRO (${selectedNtOption.value})`}
									value={calcUnitsNt(
										structure?.protein, 
										selectedNtOption.value, 
										"proteins", 
										structure?.kcal, 
										patient?.selectedWeight || 1
									)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="text"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`fats`}
									label={`FAT (${selectedNtOption.value})`}
									value={calcUnitsNt(
										structure?.fats, 
										selectedNtOption.value, 
										"fats", 
										structure?.kcal, 
										patient?.selectedWeight || 1
									)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="text"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`fiber`}
									label="FIBER"
									value={formatFloat(structure?.fiber) || 0}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
						</Row>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={structure?.active}
									onChange={() =>
										setStructure({
											...structure,
											active: !structure?.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Block">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(false);
								}}
							>
								Add exercise
							</button>
							<button
								type="button"
								className="btn btn-primary"
								style={{marginLeft: 10}}
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(true)
								}}
							>
								Add meal
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh &&
							blocks?.length > 0 && (
								<Table
									columns={blockColumns}
									data={getDataBlocksTable(blocks, patient)}
								/>
                        )}
					</CardBody>
					{ openEditBlockDialog ? (
						<>
							<EditStructureBlockDialog
								open={openEditBlockDialog || openViewBlockDialog}
								setOpen={
									openViewBlockDialog
										? setOpenViewBlockDialog
										: setOpenEditBlockDialog
								}
								data={selectedBlock}
								readOnly={openViewBlockDialog}
								meal={isMeal}
								typeStructure={"userStructure"}
								structureId={structure?._id}
								actualizeStructure={actualizeStructure}
								patient={patient}

								onSave={(block, isMeal) => {
									let newBlocks = (blocks?.length > 0)? [...blocks] : [];

									const index = newBlocks.findIndex(
										(x) => x.number === block.number
									);
									
									if (index !== -1) {
										newBlocks[index] = {
											...block,
										};
									} else {
										newBlocks.push({
											...block,
											number: newBlocks.length
										});
									}

									reCalcNutritionalValues({
										...structure,
										blocks: newBlocks
									});
									setBlocks(newBlocks);
								}}
							/>
						</>
					): <></>}
					
				</Card>
			</>
		);
}
