import { Button, FormControlLabel, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { changeStatusCompetition, deleteCompetition, getCompetitions } from "../../../../api/competition";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
} from "../../../components/tables/table";
import { getCompetitionTypes } from "../../../../api/competitionType";
import FiltersCard from "../../../components/filters/Filter";
import MyAutocomplete from "../../../components/MyAutocomplete";

function getData(competitions) {
	let data = [];
	
	for (let i = 0; i < competitions.length; ++i) {
		const elem = {};
		const comp = competitions[i];

		elem.name = getNonEmpty(comp.fullName);
		elem.competitionType = getNonEmpty(comp.competitionType?.fullName);
		elem.totalKilometers = comp.totalKilometers;
		elem.date = comp.date;
		elem.elevationGain = comp?.accumulatedPositiveSlope;
		elem.elevationLoss = comp?.accumulatedNegativeSlope;
		elem.startTime = comp?.departTime;
		elem.id = comp._id;
		elem.active = comp.active;

		data = data.concat(elem);
	}

	data.sort((a, b) => {
		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	});

	return data;
}

function getCompetitionTypesData(competitionTypes) {
	let data = [];
	for (let i = 0; i < competitionTypes?.length; ++i) {
		let elem = {};
		const actC = competitionTypes[i];

		elem._id = actC._id;
		elem.name = actC.fullName.es;

		data.push(elem);
	}

	return data;
}

const initialFilters = {
	startDate: null,
	endDate: null,
	competitionType: null
}

export default function CompetitionsPage() {
	const [data, setData] = useState([]);
	const [competitionId, setCompetitionId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [collapsed, setCollapsed] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
	const [competitionTypes, setCompetitionTypes] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	useEffect(() => {
		getCompetitions()
			.then((res) => {
				if (res.status === 200) {
					const data = getData(res.data);
					setData(data);
					setFilteredData(data);
					setRefresh(false);

					//Refresca por si acaso no se ha actualizado
					setTimeout(() => {
						getCompetitions()
						.then((res) => {
							if (res.status === 200) {
								const datta = getData(res.data);
								setData(datta);
								setFilteredData(datta);
								setRefresh(false);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get competitions.",
							});
						});
					}, 600)

				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get competitions.",
				});
			});
		getCompetitionTypes()
			.then((res) => {
				if (res.status === 200) {
					setCompetitionTypes(getCompetitionTypesData(res.data));
				}
			})
			.catch((error) => {
				if (error.response.status !== 404) {
					alertError({
						error: error,
						customMessage: "Could not get competition types.",
					});
				}
			})
	}, [refresh]);

	const columns = [
		{
			dataField: "name",
			text: "Full name",
			sort: true,
		},
		{
			dataField: "competitionType",
			text: "Competition Type",
			headerAlign: "center",
			align: "center",
			// headerStyle: { width: '200px' },
			sort: true,
		},
		{
			dataField: "totalKilometers",
			text: "distance (m)",
			headerAlign: "center",
			headerStyle: { width: '100px' },
			align: "center",
		},
		{
			dataField: "elevationGain",
			text: "elevation gain (m)",
			headerAlign: "center",
			headerStyle: { width: '100px' },
			align: "center",
		},
		{
			dataField: "elevationLoss",
			text: "elevation loss (m)",
			headerAlign: "center",
			headerStyle: { width: '100px' },
			align: "center",
		},
		{
			dataField: "date",
			text: "date",
			headerAlign: "center",
			align: "center",
			headerStyle: { width: '100px' },
			sort: true,
			formatter: getFormattedDate
		},
		{
			dataField: "startTime",
			text: "start Time",
			headerAlign: "center",
			headerStyle: { width: '80px' },
			align: "center",
		},
		{ 
			dataField: "id", 
			text: "", formatter: buttonFormatter,
			headerStyle: { width: '150px' },
			headerAlign: 'right'
		},
	];

	function getFormattedDate(date) {
		const newDate = new Date(date);
		const year = newDate.getFullYear();
		const month = String(newDate.getMonth() + 1).padStart(2, '0');
		const day = String(newDate.getDate()).padStart(2, '0');

  		return `${day}/${month}/${year}`;
	}

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-competition/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setCompetitionId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "#00F" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setCompetitionId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const handleSearch = async () => {
		if (!data.length) return;
		let startDate = filterOptions?.startDate;
		if (startDate) startDate = new Date(startDate);
		let endDate = filterOptions?.endDate;
		if (endDate) {
			endDate = new Date(endDate);
			endDate.setHours(23, 59, 59, 999);
		}
		setFilteredData(data.filter(item => {
			let filter = true;
			const itemDate = new Date(item.date);
			if (filterOptions.competitionType) filter = (filterOptions.competitionType === item?.competitionType);
			if (startDate) filter = filter && itemDate >= startDate;
			if (endDate) filter = filter && itemDate <= endDate;
			if (filter) return item;
			return false;
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions({
			...initialFilters,
			startDate: null,
			endDate: null
		});
		setRefresh(true);
	}

	const renderFiltersContent = () => {
		return <>
			<br/>
			{!refresh && (
				<>
					<FormControlLabel 
						control={
							<TextField
								id={`start date`}
								label="Start date"
								value={(filterOptions?.startDate)}
								onChange={handleChange('startDate')}
								InputLabelProps={{
									shrink: true
								}}
								margin="normal"
								variant="standard"
								type='date'
								required
							/>
							}
						style={{width: "200px"}}
					/>
					<FormControlLabel 
						control={
							<TextField
								id={`end date`}
								label="End date"
								value={(filterOptions?.endDate)}
								onChange={handleChange('endDate')}
								InputLabelProps={{
									shrink: true
								}}
								margin="normal"
								variant="standard"
								type='date'
								required
								style={{marginLeft: '20px'}}
							/>
							}
						style={{width: "200px"}}
					/>
				</>
			)}
			
			<FormControlLabel 
				control={
					<MyAutocomplete 
						options={competitionTypes}
						getOptionLabel={(option) => option.name}
						value={
							competitionTypes.find((x) => x.name === filterOptions.competitionType) 
							|| null
						}
						onChange={(event, selected) => {
							setFilterOptions({...filterOptions, competitionType: selected?.name})
						}}
						placeholder="Selected competition type"
						label={""}
						style={{flex: 1, marginLeft: '20px', marginTop: '16px'}}
						/>
					}
				style={{width: "300px"}}
			/>
		</>
	}

	const handleChange = (element) => (event) => {
		const { value } = event.target;
		setFilterOptions({
			...filterOptions,
			[element]: value
		})
	}

	return (
		<>
			<Card>
				<CardHeader title="Competitions list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-competition")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>
					<Table data={filteredData} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							competitionId?.active ? "disable" : "enable"
						} this competition?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusCompetition(competitionId.id, !competitionId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												competitionId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Competition ${
												competitionId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											competitionId?.active
												? "disable"
												: "enable"
										} competition.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this competition?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteCompetition(competitionId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Competition removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove competition.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
