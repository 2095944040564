import React, { useState } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'

function PatientTabBlock({ patientTabContent, onClick }) {

	const [tab, setTab] = useState(0)

	const handleChangeTabs = async (_, newValue) => {
		const tab = await onClick(newValue);
		if (tab) setTab(newValue)
	}

	return (
		<>
			<AppBar position="static" color="default" key="appbar">
				<Tabs
					value={tab}
					onChange={handleChangeTabs}
					variant="scrollable"
					scrollButtons="auto"
					key="tabs"
				>
					<Tab
						key="TAB1"
						label="GENERAL INFO" />
					<Tab
						key="TAB2"
						label="PHYSIOLOGY" />
					<Tab
						key="TAB3"
						label="FOLLOW-UP" />
					<Tab
						key="TAB4"
						label="STRUCTURES" />
					<Tab
						key="TAB5"
						label="DIET" />
					<Tab
						key="TAB6"
						label="MEASURES" />
					<Tab
						key="TAB7"
						label="COMPETITIONS" />
					<Tab
						key="TAB8"
						label="DOCUMENTATION" />
				</Tabs>
			</AppBar>
			<div key={`TABCONTENT${tab}`}>
				{patientTabContent[tab]}
			</div>
		</>
	)
}

export default PatientTabBlock
