import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { alertError, alertSuccess, logError } from '../../../../utils';
import { verifyEmail } from '../../../../api/auth';
import { useRouter } from '../../../hooks';
import { getUserByToken } from '../../../../api/user';

const initialValues = {
  password: '',
  confirmPassword: '',
};

function VerificationPage(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { token } = router.query;
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserByToken(token)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        alertError({
          error,
          customMessage: "Could not get user"
        })
      })
  }, [])

  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Minimum 6 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: RegisterSchema,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(async () => {
        try {
          const response = await verifyEmail({ token: token, password: values.password });
          setLoading(false);
          if (response.status === 200) {
            // alertSuccess({ customMessage: 'Succesfully registered user' })
            router.push('/verify-email-success');
          }
        } catch (error) {
          logError({ error, customMessage: 'Error registering user.' });
          alertError({ error, customMessage: 'Error registering user.' });
          // setStatus('Invalid Register')
        }
        setLoading(false);
        setSubmitting(false);
      }, 500);
    },
  });

  return (
    <div className='register-form'>
      <div className='text-center mb-10'>
        <h3 className='font-size-h1'>Bienvenido{`${user?.name? ` ${user.name}` : ""}`}!</h3>
      </div>
      <div className='text-center mb-10'>
        <h4>Introduzca una contraseña de mínimo 6 carácteres</h4>
      </div>
      <form onSubmit={formik.handleSubmit} className='form'>
        <div className='form-group'>
          <input
            placeholder='Contraseña'
            type='password'
            className='form-control'
            name='password'
            {...formik.getFieldProps('password')}
            style={{ fontSize: '16px', padding: '10px' }} // Aumenta el tamaño del campo
          />
          {formik.touched.password && formik.errors.password && (
            <div className='error-message'>{formik.errors.password}</div>
          )}
        </div>
        <div className='form-group'>
          <input
            placeholder='Repite tu contraseña'
            type='password'
            className='form-control'
            name='confirmPassword'
            {...formik.getFieldProps('confirmPassword')}
            style={{ fontSize: '16px', padding: '10px' }} // Aumenta el tamaño del campo
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='error-message'>{formik.errors.confirmPassword}</div>
          )}
        </div>
        <button type='submit' disabled={formik.isSubmitting} className='btn btn-primary'>
          {loading ? 'Registrando...' : 'Regístrate'}
        </button>
      </form>
      <br /><br/><br/><br /><br/><br/>
    </div>
  );
}

export default injectIntl(VerificationPage);
