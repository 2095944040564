import React, { useEffect, useState } from 'react'; 
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Tooltip } from "@material-ui/core";
import { Card } from "../../../../_metronic/_partials/controls";
import { Col, Row } from 'react-bootstrap';
import MyAutocomplete from '../../MyAutocomplete';
import { TableHeader } from '../../../../utils/helpers';
import Table, { buttonsStyle } from '../../tables/table';
import { ArrowDownward, ArrowUpward, Delete, Edit } from '@material-ui/icons';
import { EditOptionDialog } from './EditOptionDialog';
import { alertError } from '../../../../utils/logger';
import ConfirmDialog from '../ConfirmDialog';

const typeOfQuestions = ["YES/NO", "TEXT", "TEST"];

function sortByNumber(a, b) {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
}

function getType(element) {
    if (element?.options) return "TEST";
    if (element?.extraYes === false || element?.extraYes === true) return "YES/NO";
    return "TEXT";
}

export const EditQuestionDialog = (props) => {
    const { open, setOpen, onSave, data } = props;
    const [question, setQuestion] = useState(null);
    const [type, setType] = useState(null);
    const [openEditOption, setOpenEditOption] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedDeleteOption, setSelectedDeleteOption] = useState(null);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (data) {
            const type = getType(data);
            setType(type);
            setQuestion(data);
        }
        else {
            setType(null);
            setQuestion(null);
        }
    }, [data, open]);

    useEffect(() => {
        setRefresh(false);
    }, [refresh]);

    const optionColumns = [
        {
            dataField: "question",
            text: "question", 
        },
        {
            dataField: "extra",
            text: "Extra question",
            formatter: extraQuestion,
        },
        {
            dataField: "number",
            text: "",
            formatter: buttonsFormatter,
            headerAlign: 'right',
            align: "right",
            headerStyle: { width: '200px' }
        }
    ]

    function extraQuestion(cell, row) {
        if (cell) return row.additionalQuestion;
        return "";
    }

    function buttonsFormatter(cell, row) {
        return (
            <>
            <Tooltip title="Edit">
                <Button
                    style={buttonsStyle}
                    size="small"
                    onClick={() => {
                        setSelectedOption({...row});
                        setOpenEditOption(true);
                    }}
                >
                    <Edit />
                </Button>
            </Tooltip>
            <Tooltip title="Move up">
                <Button
                    size="small"
                    style={buttonsStyle}
                    disabled={cell === 1}
                    onClick={() => {move(cell, cell-1)}}
                >
                    <ArrowUpward />
                </Button>
            </Tooltip>
            <Tooltip title="Move down">
                <Button
                    size="small"
                    disabled={
                        cell >= question?.options?.length
                    }
                    style={buttonsStyle}
                    onClick={() => {move(cell, cell+1)}}
                >
                    <ArrowDownward />
                </Button>
            </Tooltip>
            <Tooltip title="Delete">
                <Button
                    style={buttonsStyle}
                    size="small"
                    onClick={() => {
                        setSelectedDeleteOption(cell);
                        setOpenConfirmDialog(2);
                    }}
                >
                    <Delete />
                </Button>
            </Tooltip>
            </>
        )
    }

    function move(number, nextNumber) {
        let newOptions = [...question?.options];

        const index1 = newOptions?.findIndex((x) => x.number === number);
        const index2 = newOptions?.findIndex((x) => x.number === nextNumber);
        
        newOptions[index1].number = nextNumber;
        newOptions[index2].number = number;

        setQuestion({
            ...question,
            options: newOptions
        })
        setRefresh(true);
    }

    function deleteOption(number) {
        let newOptions = [...question?.options];
        const index = newOptions?.findIndex((x) => x.number === number);
        
        if (!number) {
            alertError({
                error: null,
                customMessage: "Error deleting option"
            })
            return;
        }

        newOptions.splice(index, 1);

        for (let i = 0; i < newOptions?.length; ++i) {
            const actNumber = newOptions[i].number;
            if (actNumber > number) newOptions[i].number = actNumber - 1;
        }

        setQuestion({
            ...question,
            options: newOptions
        })
        setRefresh(true);
    }

    function getDataOptions(options) {
        let data = [...options];

        data.sort(sortByNumber)

        return data;
    }

    function changeType(selected) {
        let newQuestion = {
            number: question?.number,
            question: question?.question
        }
        if (selected === "YES/NO") {
            newQuestion.extraYes = false;
            newQuestion.extraNo = false;
        }
        else if (selected === "TEST") {
            newQuestion.additionalQuestionCondition = [];
            newQuestion.additionalQuestion = false;
            newQuestion.options = [];
        }
        setQuestion(newQuestion);
        setType(selected);
    }

    function close() {
        setOpen(false);
        setQuestion(null);
    }

    const handleChange = (element) => (event) => {
        setQuestion({...question, [element]: event.target.value});
    }

    return (
        <Dialog disableBackdropClick={true}
            fullWidth={true}
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg"
            aria-labelledby="table-dialog"
        >
            <DialogTitle id="table-dialog">{"Edit question"}</DialogTitle>
            <DialogContent>
                <Row>
                    <Col>
                    <MyAutocomplete
                        options={ typeOfQuestions }
                        getOptionLabel={(option) => option}
                        value={
                            typeOfQuestions?.find((x) => x === type) || null
                        }
                        onChange={(event, selected) => {
                            if (type) { 
                                setOpenConfirmDialog(1); 
                                setSelectedType(selected); 
                                return; 
                            }

                            changeType(selected);
                        }}
                        placeholder="Select type"
                        label={"Type"}
                    />
                    </Col>
                </Row>
                { type && (
                    <Row>
                        <Col>
                        <TextField
                            id={`question`}
                            label="Question"
                            onChange={handleChange('question')}
                            value={question?.question}
                            margin="normal"
                            variant="standard"
                            type="text"
                        />
                        </Col>
                    </Row>
                )}
                { type === "YES/NO" ? (
                    <>
                    <Row>
                        <Col sm={2} style={{marginTop: '35px'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={question?.extraYes}
                                    onChange={() =>
                                        setQuestion({
                                            ...question,
                                            extraYes: !question?.extraYes
                                        })
                                    }
                                    name="checkActive"
                                />
                            }
                            label="Extra affirmative question"
                        />
                        </Col>
                        <Col>
                        <TextField
                            id={`yesAdditionalQuestion`}
                            label="Additional affirmative question"
                            onChange={handleChange('yesAdditionalQuestion')}
                            value={question?.yesAdditionalQuestion}
                            margin="normal"
                            variant="standard"
                            type="text"
							inputProps={{ readOnly: !question?.extraYes }}
							className={`${question?.extraYes ? '' : 'readonly'}`}
                        />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={2} style={{marginTop: '35px'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={question?.extraNo}
                                    onChange={() =>
                                        setQuestion({
                                            ...question,
                                            extraNo: !question?.extraNo
                                        })
                                    }
                                    name="checkActive"
                                />
                            }
                            label="Extra negative question"
                        />
                        </Col>
                        <Col>
                        <TextField
                            id={`noAdditionalQuestion`}
                            label="Additional negative question"
                            onChange={handleChange('noAdditionalQuestion')}
                            value={question?.noAdditionalQuestion}
                            margin="normal"
                            variant="standard"
                            type="text"
							inputProps={{ readOnly: !question?.extraNo }}
							className={`${question?.extraNo ? '' : 'readonly'}`}
                        />
                        </Col>
                    </Row>
                    </>
                ) : type === "TEST" && (
                    <>
                    <br />
                    <TableHeader 
                        title="Options"
                        onClick={() => {
                            setOpenEditOption(true);
                            setSelectedOption(null);
                        }}
                        numberCol={2} 
                    />
                    { !refresh && <Table data={getDataOptions(question?.options || [])} columns={optionColumns}/> }
                    <EditOptionDialog 
                        open={openEditOption}
                        setOpen={setOpenEditOption}
                        data={selectedOption}
                        onSave={(option) => {
                            let newOptions = question?.options? [...question?.options] : [];
                            const index = newOptions?.findIndex((x) => x.number === option?.number);

                            if (index === -1) {
                                option.number = (question?.options?.length || 0) + 1;
                                newOptions.push(option);
                            }
                            else newOptions[index] = option;

                            setQuestion({
                                ...question,
                                options: newOptions
                            })
                            
                            setRefresh(true);
                        }}
                    />
                    </>
                )}

                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    style={{ marginRight: "15px" }}
                    onClick={() => {
                        close();
                    }}
                >
                    Close
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (
                            (!type || !question?.question) || 
                            (type === "TEST" && question?.options?.length === 0)
                        ) {
                            alertError({
                                error: null,
                                customMessage: "All fields are required."
                            })
                        }
                        else {
                            onSave(question, type);
                            setQuestion(null);
                            close();
                        }
                    }}
                >
                    Save
                </Button>
                <ConfirmDialog
                    title={
                        "Are you sure you want to change the type? You will lose all your extra information."
                    }
                    open={openConfirmDialog === 1}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        changeType(selectedType);
                    }}
                />
                <ConfirmDialog
                    title={
                        "Are you sure you want to delete the option?"
                    }
                    open={openConfirmDialog === 2}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                        deleteOption(selectedDeleteOption);
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}