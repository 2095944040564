import {
    Button,
    Checkbox,
    FormControlLabel,
    MuiThemeProvider,
    TextField,
    Tooltip,
    createMuiTheme,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { deleteForm, getForms } from "../../../../api/form";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

export const Forms = () => {
    const [forms, setForms] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [selectedForm, setSelectedForm] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const history = useHistory();

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        if (refresh) fetch();
        setRefresh(false);
    }, [refresh])

    function fetch() {
        getForms()
            .then((res) => {
                setForms(res.data);
            })
            .catch((error) => {
                if (error.request.status !== 404) {
                    alertError({
                        error,
                        customMessage: "Could not get forms"
                    })
                }
            })
    }

    const formColumns = [
        {
            dataField: "name",
            text: "name"
        },
        {
            dataField: "_id",
            text: "",
            formatter: buttonsFormatter,
            align: 'right',
            headerAlign: 'right'
        },
    ]

    function buttonsFormatter(cell, row) {

        return(
            <>
                <Tooltip title="Edit">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            history.push(`/edit-form/${cell}`)
                        }}
                    >
                        <Edit />
                    </Button>
                </Tooltip>
                <Tooltip title="Delete">
                    <Button
                        style={buttonsStyle}
                        size="small"
                        onClick={() => {
                            setSelectedForm(cell);
                            setOpenConfirmDialog(1);
                        }}
                    >
                        <Delete />
                    </Button>
                </Tooltip>
            </>
        )
    }

    function onDelete(form) {
        deleteForm(form)
            .then((res) => {
                alertSuccess({
                    title: "Success!",
                    customMessage: "Form deleted successfully."
                })
                setRefresh(true);
            })
            .catch((error) => {
                alertError({
                    error,
                    customMessage: "Could not delete form."
                })
            })
    }


    return (
        <Card>
            <CardHeader title="Forms">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => history.push("/edit-form")}
                    >
                        Add new
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
            <Table data={forms} columns={formColumns} />
            </CardBody>
            <ConfirmDialog
                title={
                    "Are you sure you want to delete the form?"
                }
                open={openConfirmDialog === 1}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    onDelete(selectedForm);
                    setOpenConfirmDialog(-1);
                }}
            />
        </Card>
    )
}