import React, { useState } from 'react';
import { 
    Card, 
    CardHeader, 
    CardHeaderToolbar ,
    CardBody
} from '../../../../_metronic/_partials/controls';
import { Button, TextField, Tooltip } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import LinkIcon from '@material-ui/icons/Link';
import { buttonsStyle } from '../../../components/tables/table';


export const EditPatientSchedule = (props) => {
    const { patient, onSave } = props;

    const [schedule, setSchedule] = useState(patient?.schedule);

    const handleChange = (element) => (event) => {
        setSchedule(event.target.value)
    }

    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm={9}>
                        <TextField 
                            id="schedule"
                            style={{width: '100%', height: '50px'}}
                            label={"Annual plan"}
                            value={schedule}
                            onChange={handleChange()}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{inputProps: {min: 0}}}
                            margin="none"
                            variant="standard"
                            type="text"
                            required
                        />
                        </Col>
                        <Col sm={1} style={{marginTop: 25}}>
                        <Tooltip title="Open link">
                            <Button 
                                style={buttonsStyle}
                                component="a" 
                                href={schedule} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                <LinkIcon />
                            </Button>
                        </Tooltip>
                        </Col>
                        <Col style={{marginTop: 20}}>
                        <Button
                            onClick={() => {
                                onSave(schedule);
                            }}
                            variant="outlined"
                            color="primary"
                            style={{ marginRight: "20px" }}
                        >
                            Save schedule
                        </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}