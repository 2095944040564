import { Button, FormControlLabel, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { changeStatusSportFood, deleteSportFood, getSportFoods } from "../../../../api/sportFood";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
} from "../../../components/tables/table";
import FiltersCard from "../../../components/filters/Filter";
import MyAutocomplete from "../../../components/MyAutocomplete";
import { getBrands } from "../../../../api/brand";
import { typeOptions, textureOptions, flavourOptions } from "../../../../utils/sportFoodUtils";

function getData(foods) {
	let data = [];
	for (let i = 0; i < foods.length; ++i) {
		const elem = {};
		const actF = foods[i];
		elem.name = getNonEmpty(actF.fullName);
		elem.createdAt = actF.createdAt;
		elem.id = actF._id;
		elem.active = actF.active;
		elem.weight = actF?.weight || 0;
		elem.carbohydrates = actF?.carbohydrates || 0;
		elem.proteins = actF?.proteins || 0;
		elem.fats = actF?.fats || 0;
		elem.type = actF.type || 0;
		elem.glu = actF?.glu || 0;
		elem.fru = actF?.fru || 0;
		elem.type = actF?.type;
		elem.brand = actF?.brand?.name;
		elem.texture = actF?.texture;
		elem.flavour = actF?.flavour;
		elem.sodium = actF?.sodium;
		elem.caffeine = actF?.caffeine;
		data = data.concat(elem);
	}

	data.sort((a, b) => {
		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	});

	return data;
}

const initialFilters = {
	type: null,
	brand: null,
	texture: null,
	flavour: null
}

export default function SportFoodsPage() {
	const [data, setData] = useState([]);
	const [foodId, setFoodId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [collapsed, setCollapsed] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
	const [brands, setBrands] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	useEffect(() => {
		getSportFoods()
			.then((res) => {
				if (res.status === 200) {
					const data = getData(res.data);
					setData(data);
					setFilteredData(data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sport foods.",
				});
			});
	}, [refresh]);

	useEffect(() => {
		getBrands()
			.then((res) => {
				setBrands(res.data);
			})
			.catch((error) => {
				if (error.response.status !== 404)
					alertError({
						error: error,
						customMessage: "Could not get brands."
					})
			})
	}, [])

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-sport-food/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "#00F" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setFoodId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const numbersHeaderStyle = { width: '80px' };

	const columns = [
		{
			dataField: "name",
			text: "Full name",
			sort: true,
		},
		{
			dataField: "weight",
			text: "weight",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "carbohydrates",
			text: "CHO",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "proteins",
			text: "PRO",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "sodium",
			text: "sodium",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "caffeine",
			text: "caf",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "glu",
			text: "glu ratio",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{
			dataField: "fru",
			text: "fru ratio",
			headerAlign: 'center',
			align: 'center',
			headerStyle: numbersHeaderStyle,
			formatter: (cell) => {return parseFloat(cell).toFixed(1)}
		},
		{ 
			dataField: "id", 
			text: "", 
			align: 'right',
			headerStyle: {width: '150px'},
			formatter: buttonFormatter 
		},
	];

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(data.filter(item => {
			let filter = true;
			if (filterOptions.type) filter = (filterOptions.type === item?.type);
			if (filterOptions.brand) filter = filter && (filterOptions.brand === item?.brand);
			if (filterOptions.texture) filter = filter && (filterOptions.texture === item?.texture);
			if (filterOptions.flavour) filter = filter && (filterOptions.flavour === item?.flavour);
			if (filter) return item;
			return false;
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions({
			...initialFilters,
			type: null,
			brand: null,
			texture: null,
			flavour: null
		});
		setRefresh(true);
	}

	const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControlLabel 
				control={
					<MyAutocomplete 
						options={typeOptions}
						getOptionLabel={(option) => option}
						value={
							typeOptions.find((x) => x === filterOptions.type)
							|| null
						}
						onChange={(event, selected) => {
							setFilterOptions({...filterOptions, type: selected})
						}}
						placeholder="Select type of products"
						label={""}
						style={{flex: 1, marginLeft: '20px', marginTop: '16px'}}
						/>
					}
				style={{width: "300px"}}
			/>

			<FormControlLabel 
				control={
					<MyAutocomplete 
						options={brands}
						getOptionLabel={(option) => option.name}
						value={
							brands.find((x) => x.name === filterOptions.brand)
							|| null
						}
						onChange={(event, selected) => {
							setFilterOptions({...filterOptions, brand: selected.name})
						}}
						placeholder="Select brand"
						label={""}
						style={{flex: 1, marginLeft: '20px', marginTop: '16px'}}
						/>
					}
				style={{width: "300px"}}
			/>

			<FormControlLabel 
				control={
					<MyAutocomplete 
						options={textureOptions}
						getOptionLabel={(option) => option}
						value={
							textureOptions.find((x) => x === filterOptions.texture)
							|| null
						}
						onChange={(event, selected) => {
							setFilterOptions({...filterOptions, texture: selected})
						}}
						placeholder="Select texture"
						label={""}
						style={{flex: 1, marginLeft: '20px', marginTop: '16px'}}
						/>
					}
				style={{width: "300px"}}
			/>

			<FormControlLabel 
				control={
					<MyAutocomplete 
						options={flavourOptions}
						getOptionLabel={(option) => option}
						value={
							flavourOptions.find((x) => x === filterOptions.flavour)
							|| null
						}
						onChange={(event, selected) => {
							setFilterOptions({...filterOptions, flavour: selected})
						}}
						placeholder="Select flavour"
						label={""}
						style={{flex: 1, marginLeft: '20px', marginTop: '16px'}}
						/>
					}
				style={{width: "300px"}}
			/>
		</>
	}

	return (
		<>
			<Card>
				<CardHeader title="Sport foods list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-sport-food")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>
					<Table data={filteredData} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							foodId?.active ? "disable" : "enable"
						} this food?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusSportFood(foodId.id, !foodId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												foodId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Sport food ${
												foodId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											foodId?.active
												? "disable"
												: "enable"
										} food.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this food?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteSportFood(foodId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Sport food removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove food.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
