import {
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CardBody } from "../../../../_metronic/_partials/controls";
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@material-ui/icons";
import Table, { buttonsStyle } from "../../tables/table";
import EditTramFoodDialog from "./EditTramFoodDialog";
import OnlyText from "../../textFields/OnlyText";
import { calcsFoods, calcTimeBtwTrams } from "../../../../utils/competitionCalcs";
import { formatFloat, formatHourMins } from "../../../../utils/helpers";
import { isLiquid } from "../../../../utils/sportFoodUtils";

function getEmptyFood() {
	return {
        food: null,
		name: null,
        quantity: null,
        number: null,
        observations: ""
	};
}

const EditPatientTramIntakeDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, competitionId, userId,
		foods, setFoods, foodOptions, setFoodOptions, competition, tramsXuser
	 } = props;

	const [intake, setIntake] = useState([]);
	const [selectedFood, setSelectedFood] = useState(null);
	const [openEditTramFoodDialog, setOpenEditTramFoodDialog] = useState(false);
	const [tram, setTram] = useState(getEmptyTram());

	const [refresh, setRefresh] = useState(false);

	function getEmptyTram() {
		return {
			_id: undefined,
			numTram: '',
			name: '',
			idClient: userId,
			idCompetition: competitionId,
			foods: [],
			extrInfo: '',
			time: ''
		};
	}

	useEffect(() => {
		if (!open || !data) {
			setTram(getEmptyTram());
		}
		else {
			// setTram(data);
			// setIntake(getDataFoods(data?.foods));
			actualizeNV(data);
		}
	}, [data, open, competitionId]);

    useEffect(() => {
        setRefresh(false)
    }, [refresh])

	const intakeColumns = [
		{
			dataField: "quantity",
			text: "quantity",
			formatter: quantityFormatter
		},
		{
			dataField: "name",
			text: "Food",
			align: "left",
			headerAlign: "left",
		},
		// {
		// 	dataField: "grams",
		// 	text: "grams",
		// 	align: "center",
		// 	headerAlign: "center"
		// },
		{
			dataField: "observations",
			text: "observations",
			align: "left",
			headerAlign: "left"
		},
		{
			dataField: "number",
			text: "",
			formatter: intakesButtonsFormatter,
			align: "right"
		}
	]

	function quantityFormatter(cell, row) {
		if (isLiquid(row.food)) return `${row.ml || 0} mL` 
		return `${cell || 0} ud`;
	}

	function intakesButtonsFormatter(cell) {
        const food = intake?.find((x => x.number === cell));

		return (
			<>
				<Tooltip title="Edit food">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditTramFoodDialog(true);
							setSelectedFood(food);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={cell === 0}
						onClick={() => elementMove(cell, cell-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							cell >= intake.length - 1
						}
						style={buttonsStyle}
						onClick={() => elementMove(cell, cell+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete food">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							const index = intake.findIndex((x) => x.number === cell);
							let newIntake = intake;
							newIntake.splice(index, 1);
							for (let i = index; i < newIntake?.length; ++i) newIntake[i].number--;
							
							actualizeNV({
								...tram,
								foods: newIntake
							})
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
				
			</>
		)
	}

	function elementMove(index, newIndex) {
		let elems = intake;
		let elem1 = intake[index];
		let elem2 = intake[newIndex];
		elem1.number = newIndex;
		elem2.number = index;
		elems[newIndex] = elem1;
		elems[index] = elem2;


		setIntake(elems);
		setTram({
			...tram,
			foods: intake
		})
		setRefresh(true);
	}

	function getDataFoods(foods) {
		let data = [];

		for (let i = 0; i < foods?.length; ++i) {
			let elem = {};
			const actF = foods[i];

			elem.food = actF?.food;
			elem.name = actF?.name;
			elem.observations = actF?.observations;
			elem.number = actF?.number;
			elem.quantity = actF?.quantity;
			elem.ml = actF?.ml;

			data.push(elem)
		}

		return data;
	}

	function actualizeNV(tram) {
		const index = tramsXuser.findIndex((x) => x?.numTram === tram?.numTram)
		const time = calcTimeBtwTrams(tram, index, tramsXuser, competition);
		const splitTime = formatHourMins(time)

		const { waterMl, waterMlH, choG, choGH, sodiumMg, sodiumMgL, caffeine } = calcsFoods(tram, time);

		const newTram = {
			...tram,
			splitTime,
			choG,
			waterMl,
			sodiMg: sodiumMg,
			caffeine,
			waterMlH,
			choGH,
			sodiumMgL
		}
		setTram(newTram);
		setIntake(getDataFoods(newTram?.foods));
		// calcsFoods(tram);
	}

	function formatFixedZero(cell) { return parseFloat(cell).toFixed(0);}
	
	return (
		<Dialog disableBackdropClick={true} 
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<br />
				<Card>
					<CardBody>
						<Row>
							<Col>
							<TextField
								id={`longitude`}
								label="Distance"
								value={tram?.longitude || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
							<Col>
							<TextField
								id={`elevationGain`}
								label="Elevation gain"
								value={tram?.positiveUnevenness || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
							<Col>
							<TextField
								id={`elevationLoss`}
								label="Elevation loss"
								value={tram?.negativeUnevenness || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
						</Row>
						<Row>
							<Col>
							<TextField
								id={`water`}
								label="Water (ml)"
								value={formatFixedZero(tram?.waterMl) || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
							<Col>
							<TextField
								id={`waterMlH`}
								label="Water (mL/H)"
								value={formatFixedZero(tram?.waterMlH) || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
						<Col>
							<TextField
								id={`choG`}
								label="CHO (g)"
								value={formatFixedZero(tram?.choG) || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
							<Col>
							<TextField
								id={`choGH`}
								label="CHO (g/h)"
								value={formatFixedZero(tram?.choGH) || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
							<Col>
							<TextField
								id={`sodi`}
								label="NA (mg)"
								value={formatFixedZero(tram?.sodiMg) || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
							<Col>
							<TextField
								id={`naMGL`}
								label="Na (mg/L)"
								value={formatFixedZero(tram?.sodiumMgL) || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
							<Col>
							<TextField
								id={`caffeine`}
								label="CAF (mg)"
								value={formatFixedZero(tram?.caffeine) || 0}
								inputProps={{ readOnly: true }}
								required
								margin="normal"
								variant="standard"
								type="number"
								className="readonly"
							/>
							</Col>
						</Row>
						<Row>
							<Col>
								{ (tram?.number === 0) ? (
									<TextField 
									id={`time`}
									label="Elapsed time"
									value={tram?.time}
									inputProps={{ readOnly: true }}
									required
									margin="normal"
									variant="standard"
									type="time"
									className="readonly"
									/>
								) : (
									<OnlyText 
										title={"Elapsed time"}
										element={"time"}
										data={tram}
										handleChange={() => (event) => {
											actualizeNV({
												...tram,
												time: event?.target?.value
											})
										}}
										type="time"
									/>
								)}
							</Col>
							<Col>
								<TextField
									id={`splitTime`}
									label="Split time"
									value={tram?.splitTime || "10:00"}
									inputProps={{ readOnly: true }}
									required
									margin="normal"
									variant="standard"
									type="time"
									className="readonly"
								/>
							</Col>
						</Row>
						<br />
						<Row>
							<Col><h3>Intake</h3></Col>
							<Col></Col><Col></Col><Col></Col>
							<Col>
								<button
									type="button"
									className="btn btn-primary"
									style={{marginLeft: 10}}
									onClick={() => {
										setOpenEditTramFoodDialog(true);
										setSelectedFood({
											...getEmptyFood(),
											number: intake?.length
										});
									}}
								>
									Add food
								</button>
							</Col>
						</Row>
						{!refresh && (
								<Table
									columns={intakeColumns}
									data={intake}
								/>
						)}
					</CardBody>
					<EditTramFoodDialog 
						open={openEditTramFoodDialog}
						setOpen={setOpenEditTramFoodDialog}
						foodOptions={foodOptions}
						data={selectedFood}
						onSave={(food) => {
							let newIntake = [
								...intake
							]

							const index = intake?.findIndex((x) => x.number === food.number);

							if (index === -1) newIntake.push(food);
							else newIntake[index] = food;
							
							actualizeNV({
								...tram,
								foods: newIntake
							})
							setRefresh(true);
						}}
					/>
				</Card>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						setOpen(false);
						onSave(tram);
						setTram(getEmptyTram());
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditPatientTramIntakeDialog;