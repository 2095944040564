import React from 'react';
import { Android, Apple } from "@material-ui/icons";
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

function VerificationSuccessPage() {
  return (
    <div className='success-page' style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Cuenta registrada satisfactoriamente!</h2>
      <p>Descargue la aplicación para iOS o Android</p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
        <a href='https://apps.apple.com/app/idXXXXXXXXX' target='_blank' rel='noopener noreferrer'>
          <img 
            src={toAbsoluteUrl("/media/logos/appStoreDownload.png")}
            style={{ maxWidth: '200px' }}
            alt="Logo"
          />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.example.app' target='_blank' rel='noopener noreferrer'>
            <SVG src={toAbsoluteUrl('/media/svg/googlePlayDownload.svg')} 
            style={{ maxWidth: '200px', height: 'auto' }}/>
        </a>
      </div>
      <br /><br/><br/><br /><br/><br/><br /><br/><br/>
    </div>
  );
}

export default VerificationSuccessPage;