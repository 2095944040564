import {
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
	Tooltip,
} from "@material-ui/core";
import { Delete, Edit, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../_metronic/_partials/controls";
import { SERVER_URL } from "../api";
import { alertError } from "../utils/logger";
import PreviewDialog from "../app/components/dialogs/PreviewDialog";
import Table, { buttonsStyle } from "../app/components/tables/table";
import EditFoodDialog from "../app/components/dialogs/EditFoodDialog";
import { Col, Row } from "react-bootstrap";
import { formatFloat } from "../utils/helpers";
import { recipeCalcs } from "../utils/structuresCalcs";
import Editor from "../app/components/editor/Editor";
import MultilanguageTabBlock from "../app/components/MultilanguageTabBlock";

export function getEmptyFood() {
	return {
		fullName: null,
		grams: '0',
		kcals: 0,
		proteins: 0,
		carbohydrates: 0,
		fats: 0,
		portionConsumed: 0,
		fiber: 0,
	};
}

export function getEmptyFoodPlate() {
	return {
		foods: [],
		description: null,
		active: true,
		fullName: null,
		recipe: null,
		totalKcals: 0,
		totalProteins: 0,
		totalCarbohydrates: 0,
		totalFats: 0,
		totalFiber: 0,
		preparationMinutes: null,
		imageURL: null,
	}
}

export function getEmptyNutritionalValues() {
	return {
		kcals: 0,
		proteins: 0,
		carbohydrates: 0,
		fats: 0,
		fiber: 0,
	}
}

export const EditRecipeInfo = (props) => {
    const { foodPlate, setFoodPlate, allFoods, 
            selectedImage, setSelectedImage, foodPlateId, 
            changes, setChanges, isStructure
    } = props;

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedFood, setSelectedFood] = useState(getEmptyFood());
	const [openEditFoodDialog, setOpenEditFoodDialog] = useState(false);
	const [selectedIndexFood, setSelectedIndexFood] = useState(-1);
	const [nutritionValues, setNutritionValues] = useState(getEmptyNutritionalValues());
	const [foods, setFoods] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
		if (!foodPlateId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [foodPlate])

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    useEffect(() => {
        if (nutritionValues.kcals === 0 && foodPlate?.foods?.length > 0) {
            recalcNutrition(foodPlate)
        }
    }, [])

	async function recalcNutrition(recipe) {
		const calcs = await recipeCalcs(recipe);
		setNutritionValues(calcs);
        setFoodPlate({
            ...recipe,
            totalKcals: calcs.kcals,
            totalProteins: calcs.proteins,
            totalCarbohydrates: calcs.carbohydrates,
            totalFats: calcs.fats,
            totalFiber: calcs.fiber
        })
	}

	const foodsTable = [
		{
			dataField: "fullName",
			text: "name",
			headerStyle: {width: '400px'}
		},
		{
			dataField: "grams",
			text: "grams",
			headerAlign: "center",
			align: "center",
			formatter: gramsFormatter
		},
		{
			dataField: "kcals",
			text: "kcal",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
            dataField: "carbohydrates",
			text: "cho",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
        {
            dataField: "proteins",
            text: "pro",
            headerAlign: "center",
            align: "center",
            formatter: numbersFormat,
        },
		{
			dataField: "fats",
			text: "fat",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
			dataField: "fiber",
			text: "fiber",
			headerAlign: "center",
			align: "center",
			formatter: numbersFormat,
		},
		{
			dataField: "_id",
			text: "",
			formatter: foodsButtonFormatter,
			headerStyle: {width: '120px'}
		}
	]

	function gramsFormatter(cell, row) {
		if (row?.selectedPortion >= 0 && row?.portionQuant >= 0) {
			const portionName = row?.portions?.find((x) => x._id === row.selectedPortion).name;
			return `${row.portionQuant} ${portionName} (${cell} g)`
		}
		return cell;
	}

	function numbersFormat(cell) { return formatFloat(cell); }

	function foodsButtonFormatter(cell) {
		const index = foods.findIndex((x) => x._id === cell);
		const food = foods.find((x) => x._id === cell);

		return (
			<>
				{cell != 0 && (
					<> 
						<Tooltip title="Edit">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenEditFoodDialog(true);
									setSelectedFood(food)
									setSelectedIndexFood(index)
								}}
							>
								<Edit />
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {deleteFood(index, food)}}
							>
								<Delete />
							</Button>
						</Tooltip>
					</>
				) 
				}
				
			</>
		)
	}

	function deleteFood(index, food) {
		let newFoods = foods;
		if (index !== -1) newFoods.splice(index, 1);
		setFoods(newFoods);
		if (foodPlate?.foods?.length > 0) recalcNutrition({
        	...foodPlate,
			foods: getIdsFoods(newFoods)
		})
		setRefresh(true);
	}

	function getIdsFoods(foods) {
		let data = [];
		
		for (let i = 0; i < foods.length; ++i) {
			let elem = {};
			const actF = foods[i];
			elem.foodId = actF._id;
			elem.grams = actF.grams;
			elem.selectedPortion = actF?.selectedPortion || -1;
			elem.portionQuant = actF?.portionQuant || 0;
			data.push(elem);
		}

		return data;
	}

    useEffect(() => {
		if (foodPlate && allFoods.length > 0 && foods.length <= 0) {
			const num = foodPlate?.foods?.length;
			let data = [];
			for (let i = 0; i < num; ++i) {
				const food = allFoods.find((x) => x._id === foodPlate.foods[i].foodId)
				const actF = foodPlate.foods[i];
				if (food) 
					data.push({
						...food,
						fullName: food.fullName.es,
						grams: actF.grams,
						selectedPortion: actF?.selectedPortion || -1,
						portionQuant: actF?.portionQuant || 0
					});
				else {
					alertError({
						error: "Could not get food plate",
						customMessage: "Could not get food plate.",
					});
				}
			}
			setFoods(getFoodsData(data));
		}
	}, [foodPlate, allFoods]);

    const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!foodPlate[element]) foodPlate[element] = {};
			let newText = foodPlate[element];
			newText[lang] = event.target.value;
			setFoodPlate({ ...foodPlate, [element]: newText });
		} else
			setFoodPlate({
				...foodPlate,
				[element]: event.target.value,
			});
	};

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
			if (value === " ") return;
			if (!foodPlate[element]) foodPlate[element] = {};
			let newText = foodPlate[element];
			newText[lang] = value;
			setFoodPlate({ ...foodPlate, [element]: newText });
		} else setFoodPlate({ ...foodPlate, [element]: value });
	};

	function getFoodsData(foods) {
		let data = [];

		for (let i = 0; i < foods?.length; ++i) data.push(getFoodData(foods[i]))
		
		return data;
	}

	function getFoodData(food) {
		const elem = {};

		elem._id = food._id;
		const completeFood = allFoods.find((x) => x._id === food._id);

		elem.fullName = food.fullName;
		elem.portionConsumed = parseFloat(food?.portionConsumed) || 1;

		const grams = parseInt(food.grams) || 0; 
		elem.grams = grams;
		elem.kcals = completeFood.kcals*grams/100;
		elem.proteins = completeFood.proteins*grams/100;
		elem.carbohydrates = completeFood.carbohydrates*grams/100;
		elem.fats = completeFood.fats*grams/100;
		elem.fiber = completeFood.fiber*grams/100;
		elem.portions = completeFood?.portions || [];
		elem.selectedPortion = (food?.selectedPortion === undefined || food?.selectedPortion < 0) ?  -1 : food?.selectedPortion;
		elem.portionQuant = food?.portionQuant || 0;

		return elem;
	}

	const addNew = () => {
		setSelectedFood(null);
		setOpenEditFoodDialog(true);
		setSelectedIndexFood(-1);
	}

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`description`}
					label="Title"
					value={
						(foodPlate.description &&
							foodPlate.description[lang]) ||
						""
					}
					onChange={handleChange("description", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
				<br />
                {!refresh && 
                    <Editor
                        body={(foodPlate.recipe && foodPlate.recipe[lang]) || ""}
                        setBody={(new_body) =>
                            handleChangeEditor("recipe", lang, new_body)
                        }
                        className="max-height"
                        lang={lang}
                        placeholder={"Enter plate recipe here..."}
                        name="Recipe"
                    />
                }
			</>
		);
	};
    
    function imageRender() {
        return (
            <>
                <br />
                <label htmlFor={"upload-image"} className="ml-0">
                    <input
                        style={{ display: "none" }}
                        id={"upload-image"}
                        name={"upload-image"}
                        type="file"
                        accept={"image/*"}
                        onChange={(e) => {
                            setSelectedImage(e.target.files[0]);
                        }}
                    />
                    <Button
                        style={{ marginRight: "15px" }}
                        color="secondary"
                        component="span"
                        variant="outlined"
                    >
                        {selectedImage ||
                        (foodPlate.imageURL &&
                            foodPlate.imageURL !== "")
                            ? "Change image"
                            : "Upload image"}
                    </Button>
                </label>
                {(selectedImage ||
                    (foodPlate.imageURL &&
                        foodPlate.imageURL !== "")) && (
                    <>
                        <Tooltip title={"Preview image"}>
                            <Button
                                size="small"
                                onClick={() =>
                                    setOpenPreviewDialog(true)
                                }
                                style={{
                                    ...buttonsStyle,
                                    marginRight: "15px",
                                }}
                            >
                                <Visibility />
                            </Button>
                        </Tooltip>
                        <PreviewDialog
                            title={"Preview image"}
                            open={openPreviewDialog}
                            setOpen={setOpenPreviewDialog}
                            src={
                                selectedImage
                                    ? URL.createObjectURL(selectedImage)
                                    : `${SERVER_URL}/${foodPlate.imageURL}`
                            }
                        />
                        <span>
                            {selectedImage
                                ? selectedImage?.name
                                : foodPlate.imageURL &&
                                    foodPlate.imageURL !== ""
                                ? foodPlate.imageURL?.split(/-(.*)/s)[1]
                                : ""}
                        </span>
                        <Tooltip title={"Delete image"}>
                            <Button
                                size="small"
                                onClick={() => {
                                    setSelectedImage(null);
                                    setFoodPlate({
                                        ...foodPlate,
                                        imageURL: null,
                                    });
                                }}
                                style={{
                                    ...buttonsStyle,
                                    marginRight: "15px",
                                }}
                            >
                                <Delete />
                            </Button>
                        </Tooltip>
                    </>
                )}
                <br />
                <br />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={foodPlate.active}
                            onChange={() =>
                                setFoodPlate({
                                    ...foodPlate,
                                    active: !foodPlate.active,
                                })
                            }
                            name="checkActive"
                        />
                    }
                    label="Active"
                />
            </>
        )
    }

    return (
        <>
            <MultilanguageTabBlock
                multilanguageTabContent={
                    renderMultilanguageTabContent
                }
            />
            <br />
            <Row>
                <Col>
                    <TextField
                        id={`kcals`}
                        label="KCAL"
                        inputProps={{ readOnly: true }}
                        value={formatFloat(nutritionValues?.kcals)}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
                <Col>
                    <TextField
                        id={`carbohydrates`}
                        label="CHO"
                        inputProps={{ readOnly: true }}
                        value={formatFloat(nutritionValues.carbohydrates.toFixed(2))}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
                <Col>
                    <TextField
                        id={`proteins`}
                        label="PRO"
                        inputProps={{ readOnly: true }}
                        value={formatFloat(nutritionValues.proteins.toFixed(2))}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
                <Col>
                    <TextField
                        id={`fats`}
                        label="FAT"
                        inputProps={{ readOnly: true }}
                        value={formatFloat(nutritionValues.fats.toFixed(2))}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
                <Col>
                    <TextField
                        id={`fiber`}
                        label="FIBER"
                        inputProps={{ readOnly: true }}
                        value={formatFloat(nutritionValues.fiber.toFixed(2))}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
            </Row>
            <br />
            
            <Row>
                <Col sm={2}><h4>Ingredients</h4></Col>
                <Col className="text-right">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => addNew()}
                    >
                        Add new
                    </button>
                </Col>
            </Row>
    
            {!refresh && (
                <Table
                    columns={foodsTable}
                    data={foods}
                />
            )}
            <EditFoodDialog
                open={openEditFoodDialog}
                setOpen={setOpenEditFoodDialog}
                data={selectedFood}
                readOnly={false}
                foods={allFoods}
                selectedFoods={foodPlate?.foods}
                onSave={(food) => {
                    let newFoods = foods;
                    const number = selectedIndexFood;
                    const newFood = getFoodData(food);
                    
                    if (number !== -1) {
                        newFoods[number] = newFood;
                    }
                    else {
                        newFoods.push({
                            ...newFood,
                        })
                    }
                    setFoods(newFoods);
                    const newRecipe = {
                        ...foodPlate,
                        foods: getIdsFoods(newFoods)
                    }
                    recalcNutrition(newRecipe)
                    setRefresh(true)
                }}
            />
            {!isStructure && imageRender()}
        </>
    );

}

export function getEmptyFoodPlateStructure(structureId, typeStructure) {
    return {
        _id: null,
        structure: structureId,
        typeStructure: typeStructure,
        number: null,
        active: true,
        foods: [],
        description: {
          es: ""
        },
        recipe: {
          es: ""
        },
        totalKcals: 0,
        totalProteins: 0,
        totalCarbohydrates: 0,
        totalFats: 0,
        totalFiber: 0,
        preparationMinutes: null,
        imageURL: null,
      }
}