import {
	Button,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { alertError } from "../../../../../utils/logger";
import { useSkeleton } from "../../../../hooks/useSkeleton";
import { getCompetitions } from '../../../../../api/competition'
import { getUserCompetitionsByUserId } from "../../../../../api/userCompetitions";
import Table, { buttonsStyle } from "../../../../components/tables/table";
import { Edit, Visibility } from "@material-ui/icons";
import ViewPatientCompetition from "./ViewPatientCompetition";
import EditPatientCompetition from "./EditPatientCompetition";
import { getAllTramsUser } from "../../../../../api/tramXuser";
import { calcsFoods } from "../../../../../utils/competitionCalcs";
import { getMins } from "../../../../../utils/helpers";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyUserCompetitions() {
	return {
		competitions: null
	};
}

export default function EditPatientsCompetitions(props) {
	const { userId, changes, setChanges, userCompetitions, setUserCompetitions, patient, handleChangePatient } = props;
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const history = useHistory();

	const [competitions, setCompetitions] = useState([]);

	const [selectedCompetition, setSelectedCompetition] = useState(null);
	const [openViewPatientCompetition, setOpenViewPatientCompetition] = useState(false);
	const [openEditPatientCompetition, setOpenEditPatientCompetition] = useState(false);
	const [tramsXuser, setTramsXuser] = useState([]);
	const [allTramsXuser, setAllTramsXuser] = useState([]);

	const [refresh, setRefresh] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
		enableLoading
	} = useSkeleton();

	useEffect(() => {
		if (competitions.length === 0)
			getCompetitions()
			.then((res) => {
				if (res.status === 200) {
					setCompetitions(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get competitions.",
				});
			});

		
		// refreshData();
	}, [userId, disableLoadingData])

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		if (!openEditPatientCompetition) refreshData();
	}, [openEditPatientCompetition])

	function refreshData() {
		if (userCompetitions === null && userId)
			getUserCompetitionsByUserId(userId)
				.then((res) => {
					if (res.status === 200) {
						setUserCompetitions(res.data);
						setRefresh(true);
					}
				})
				.catch((error) => {
					setRefresh(true);
					if (error.response.status !== 404)
						alertError({
							error: error,
							customMessage: "Could not get user competitions.",
						});
				});
		
		if (allTramsXuser) 
			getAllTramsUser(userId)
				.then((res) => {
					if (res.status === 200) {
						setRefresh(true);
						setAllTramsXuser(res.data);
					}
				})
				.catch((error) => {
					if (error.response.status !== 404) {
						setRefresh(true);
						alertError({
							error: error,
							customMessage: "Could not get trams X user"
						})
					}
				});
	}

	function getSpecificTramsData(elem, defTramUser, timme) {
		let totalCalcs = {waterMl: 0, waterMlH: 0, choG: 0, choGH: 0, sodiMg: 0, sodiMgL: 0, caffeine: 0};
		
		for (let j = 0; j < defTramUser.length; ++j) {
			const actD = defTramUser[j];
			const time = timme || (actD?.time) || 0;
			const calcs = calcsFoods(actD, parseFloat(getMins(time) || 1));
			totalCalcs.waterMl += calcs.waterMl;
			totalCalcs.waterMlH += calcs.waterMlH;
			totalCalcs.choG += calcs.choG;
			totalCalcs.choGH += calcs.choGH;
			totalCalcs.sodiMg += calcs.sodiumMg;
			totalCalcs.sodiMgL += calcs.sodiumMgL;
			totalCalcs.caffeine += calcs.caffeine;
		}
		
		elem.waterMl = totalCalcs.waterMl;
		elem.waterMlH = totalCalcs.waterMlH;
		elem.choG = totalCalcs.choG;
		elem.choGH = totalCalcs.choGH;
		elem.sodiMg = totalCalcs.sodiMg;
		elem.sodiMgL = totalCalcs.sodiMgL;
		elem.caffeine = totalCalcs.caffeine;
		elem.time = timme;
	}

	function getCompetitionsData(userCompetitions, tramsXuser) {
		let data = [];
		for (let i = 0; i < userCompetitions?.competitions?.length; ++i) {
			let elem = {}
			const act = userCompetitions.competitions[i];
			
			const defTramUser = tramsXuser.filter((x) => {
				if (x.idCompetition === act.competitionId && act.date === x.date) return true;
				return false;
			})
			getSpecificTramsData(elem, defTramUser, (act?.userFeedback?.totalTime) || (act?.totalTime));

			const completeC = competitions.find((x) => x._id === act.competitionId);
			elem.longitude = completeC?.totalKilometers || 0;
			elem.positiveUnevenness = completeC?.accumulatedNegativeSlope || 0;
			elem.negativeUnevenness = completeC?.accumulatedPositiveSlope || 0;
			
			elem._id = act._id;
			elem.competitionId = act.competitionId;
			elem.competitionName = act.competitionName;
			elem.date = act.date;

			data.push(elem);
		}

		return data;
	}

	const competitionsTable = [
		{
			dataField: "competitionName",
			text: "name"
		},
		{ 
			dataField: "longitude", 
			text: "Distance (km)",
			formatter: fixedZero,
			headerAlign: "center",
			headerStyle: { width: '90px' },
			align: "center",
		},
		{ 
			dataField: "positiveUnevenness", 
			text: "Elevation gain (m)",
			formatter: fixedZero,
			headerAlign: "center",
			headerStyle: { width: '95px' },
			align: "center",
		},
        { 
			dataField: "negativeUnevenness", 
			text: "Elevation loss (m)",
			formatter: fixedZero,
			headerAlign: "center",
			headerStyle: { width: '95px' },
			align: "center",
		},
        { 
			dataField: "time", 
			text: "time",
			headerAlign: "center",
			headerStyle: { width: '80px' },
			align: "center",
		},
        {
            dataField: "choGH",
            text: "CHO (g/h)",
            align: 'center',
            headerAlign: "center",
			headerStyle: { width: '80px' },
            formatter: fixedZero
        },
        {
            dataField: "waterMlH",
            text: "water (ml/h)",
            align: 'center',
            headerAlign: "center",
			headerStyle: { width: '80px' },
            formatter: fixedZero
        },
        {
            dataField: "sodiMgL",
            text: "Na (mg/l)",
            align: 'center',
            headerAlign: "center",
			headerStyle: { width: '80px' },
            formatter: fixedZero
        },
        {
            dataField: "caffeine",
            text: "caf",
            align: 'center',
            headerAlign: "center",
			headerStyle: { width: '85px' },
            formatter: fixedZero
        },
		{
			dataField: "date",
			text: "date",
			headerAlign: "center",
			headerStyle: { width: '125px' },
			align: "center",
			formatter: dateFormatter
		},
		{
			dataField: "competitionId",
			text: "",
			formatter: competitionButtonsFormatter,
			align: "right",
			headerStyle: { width: '110px' },
		}
	]
    function fixedZero(cell) {return parseFloat(cell).toFixed(0);}
    function fixedOne(cell) {return parseFloat(cell).toFixed(1);}

	function competitionButtonsFormatter(cell, row) {
		const index = cell;
		
		const competition = userCompetitions?.competitions?.find(
			(x) => x.competitionId === cell && x.date === row.date
		)

		return (
			<>
				{cell != 0 && (
					<> 
						<Tooltip title="Edit">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenEditPatientCompetition(true);
									setSelectedCompetition(competition);
								}}
							>
								<Edit />
							</Button>
						</Tooltip>
						<Tooltip title="View">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenViewPatientCompetition(true);
									setSelectedCompetition(competition);
								}}
							>
								<Visibility />
							</Button>
						</Tooltip>
					</>
				) 
				}
				
			</>
		)
	}

	function dateFormatter(dateString) {
		const date = new Date(dateString);

		return date.toLocaleString('es-ES', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false, // Para formato 24 horas
		}).replace(',', ' at');
		// const day = String(date.getUTCDate()).padStart(2, '0');
		// const month = String(date.getUTCMonth() + 1).padStart(2, '0');
		// const year = date.getUTCFullYear();
		// const hours = String(date.getUTCHours()).padStart(2, '0');
		// const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    	// return `${day}-${month}-${year} at ${hours}:${minutes}`;
	}

	if (isLoadingData) return <ContentSkeleton />
	else if (openEditPatientCompetition)
		return <EditPatientCompetition
			userId={userId}
			dataComp={selectedCompetition}
			competitions={competitions}
			setUserCompetitions={setUserCompetitions}
			changes={changes}
			setChanges={setChanges}
			tramsXuser={tramsXuser}
			setTramsXuser={setTramsXuser}
			patient={patient}
			userCompetitions={userCompetitions}
			setOpen={setOpenEditPatientCompetition}
			/>
	else if (openViewPatientCompetition) 
		return <ViewPatientCompetition
			dataComp={selectedCompetition}
			competitions={competitions}
			userId={userId}
			setOpen={setOpenViewPatientCompetition}
			userCompetitions={userCompetitions}
			setUserCompetitions={setUserCompetitions}
			tramsXuser={tramsXuser}
			setTramsXuser={setTramsXuser}
			changes={changes}
			setChanges={setChanges}
			patient={patient}
		/>
	else
		return (
			<>
				<Card>
					<CardBody>
						<TextField
							id={`notes`}
							label="Notes"
							onChange={handleChangePatient('competitionsNotes')}
							value={patient?.competitionsNotes}
							margin="normal"
							variant="standard"
							multiline
							type="text"
						/>
					</CardBody>

					<CardHeader title="Competitions">
						<CardHeaderToolbar />
					</CardHeader>
					<CardBody>
						{!refresh && (
							<Table 
								columns={competitionsTable}
								data={getCompetitionsData(userCompetitions, allTramsXuser)}
							/>
						)}
					</CardBody>
					
                    <h4>FCA</h4>
                </Card>
			</>
		);
}
