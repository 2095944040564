import {
    Button,
    Checkbox,
    FormControlLabel,
    MuiThemeProvider,
    TextField,
    Tooltip,
    createMuiTheme,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { deleteForm, getFormById, postForm, updateForm } from "../../../../api/form";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory, useParams } from "react-router-dom";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { Col, Row } from "react-bootstrap";
import { TableHeader } from "../../../../utils/helpers";
import { EditQuestionDialog } from "../../../components/dialogs/forms/EditQuestionDialog";

function getType(element) {
    if (element?.options) return "TEST";
    if (element?.extraYes === false || element?.extraYes === true) return "YES/NO";
    return "TEXT";
}

function sortByNumber(a, b) {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
}

const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export const EditForm = () => {
    const [form, setForm] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [openEditQuestion, setOpenEditQuestion] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedDeleteQuestion, setSelectedDeleteQuestion] = useState(null);

    const [refresh, setRefresh] = useState(false);
    const [changes, setChanges] = useState(-2);
    const history = useHistory();
    const formId = useParams().id;

    useEffect(() => {
        if (formId) {
            getFormById(formId)
                .then((res) => {
                    setForm(res.data);
                })
                .catch((error) => {
                    alertError({
                        error,
                        customMessage: "Could not obtain form"
                    });
                    history.push('/forms');
                });
        }
        else {
            setForm({
                texts: [],
                tests: [],
                yesNo: []
            })
        }
    }, []);

    useEffect(() => {
        setRefresh(false);
    }, [refresh]);

    useEffect(() => {
        setChanges(changes+1);
    }, [form]);

    const handleChange = (element) => (event) => {
        setForm({...form, [element]: event.target.value });
    }

    const questionColumns = [
        {
            dataField: "number",
            text: "type",
            headerStyle: { width: '200px' },
            formatter: typeFormat
        },
        {
            dataField: "question",
            text: "question"
        },
        {
            dataField: "number",
            text: "",
            headerAlign: 'right',
            align: "right",
            formatter: buttonsFormatter,
            headerStyle: { width: '200px' }
        }
    ]

    function typeFormat(cell, row) {
        const type = getType(row);
        return type;
    }

    function buttonsFormatter(cell, row) {
        return (
            <>
            <Tooltip title="Edit">
                <Button
                    style={buttonsStyle}
                    size="small"
                    onClick={() => {
                        setSelectedQuestion(row);
                        setOpenEditQuestion(true);
                    }}
                >
                    <Edit />
                </Button>
            </Tooltip>
            <Tooltip title="Move up">
                <Button
                    size="small"
                    style={buttonsStyle}
                    disabled={cell === 1}
                    onClick={() => {move(cell, cell-1);}}
                >
                    <ArrowUpward />
                </Button>
            </Tooltip>
            <Tooltip title="Move down">
                <Button
                    size="small"
                    disabled={
                        cell === (getTotalSize(form))
                    }
                    style={buttonsStyle}
                    onClick={() => {move(cell, cell+1);}}
                >
                    <ArrowDownward />
                </Button>
            </Tooltip>
            <Tooltip title="Delete">
                <Button
                    style={buttonsStyle}
                    size="small"
                    onClick={() => {
                        setSelectedDeleteQuestion(cell);
                        setOpenConfirmDialog(3);
                    }}
                >
                    <Delete />
                </Button>
            </Tooltip>
            </>
        )
    }

    function getInfo(num) {
        let index = form?.texts?.findIndex((x) => x.number === num);
        if (index !== -1) return { index, type: "TEXT" };
        
        index = form?.tests?.findIndex((x) => x.number === num);
        if (index !== -1) return { index, type: "TEST" };
        
        index = form?.yesNo?.findIndex((x) => x.number === num);
        if (index !== -1) return { index, type: "YES/NO" };
        return null;
    }

    function deleteQuestion(number) {
        let newForm = {...form};
        const info = getInfo(number);
        if (!info) return;

        switch (info.type) {
            case "TEXT": newForm.texts.splice(info.index, 1); break;
            case "TEST": newForm.tests.splice(info.index, 1); break;
            case "YES/NO": newForm.yesNo.splice(info.index, 1); break;
        }

        for (let i = 0; i < newForm?.texts?.length; ++i) {
            const num = newForm.texts[i].number;
            if (num > number) newForm.texts[i].number = num-1;
        }

        for (let i = 0; i < newForm?.tests?.length; ++i) {
            const num = newForm.tests[i].number;
            if (num > number) newForm.tests[i].number = num-1;
        }

        for (let i = 0; i < newForm?.yesNo?.length; ++i) {
            const num = newForm.yesNo[i].number;
            if (num > number) newForm.yesNo[i].number = num-1;
        }

        setForm({...newForm});
        setRefresh(true);
    }

    function move(number, nextNumber) {
        let newForm = {...form};
        
        const info1 = getInfo(number);
        const info2 = getInfo(nextNumber);
        if (!info1 || !info2) return;

        switch(info1.type) {
            case "TEXT": newForm.texts[info1.index].number = nextNumber; break;
            case "TEST": newForm.tests[info1.index].number = nextNumber; break;
            case "YES/NO": newForm.yesNo[info1.index].number = nextNumber; break;
        }

        switch(info2.type) {
            case "TEXT": newForm.texts[info2.index].number = number; break;
            case "TEST": newForm.tests[info2.index].number = number; break;
            case "YES/NO": newForm.yesNo[info2.index].number = number; break;
        }

        setForm({...newForm});
        setRefresh(true);
    }

    function getTotalSize(form) {
        return ((form?.texts?.length || 0) + (form?.yesNo?.length || 0) + (form?.tests?.length || 0));
    }

    function getDataQuestions(form) {
        let data = [];

        const texts = (form?.texts || []), tests = (form?.tests || []), yesNo = (form?.yesNo || [])
        data = texts.concat(tests);
        data = data.concat(yesNo);
        data.sort(sortByNumber)

        return data;
    }

    function save() {
        if (!form?._id) {
            postForm(form)
                .then((res) => {
                    if (res.status === 201) {
                        alertSuccess({
                            title: "Success!",
                            customMessage: "Form saved!"
                        })
                        history.push("/forms");
                    }
                })
                .catch((error) => {
                    alertError({
                        error,
                        customMessage: "Could not save form."
                    })
                })
        }
        else {
            updateForm(form._id, form)
                .then((res) => {
                    if (res.status === 200) {
                        alertSuccess({
                            title: "Success!",
                            customMessage: "Form saved!"
                        })
                        history.push("/forms");
                    }
                })
                .catch((error) => {
                    alertError({
                        error,
                        customMessage: "Could not save form."
                    })
                })
        }
    }

    return (
        <Card>
            <CardHeader title={form?._id?'Form: ' + (form.name):'New form'}>
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            if (changes <= 0) history.push('/forms');
                            else setOpenConfirmDialog(1);
                        }}
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => save()}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "20px" }}
                    >
                        Save form
                    </Button>
                    <ConfirmDialog
                        title={
                            "Are you sure you want to go back? You will lose all your changes"
                        }
                        open={openConfirmDialog === 1}
                        setOpen={setOpenConfirmDialog}
                        onConfirm={() => {
                            history.push("/forms")
                        }}
                    />
                    { formId && (
                        <>
                        <MuiThemeProvider theme={theme}>
                            <Button
                                onClick={() => setOpenConfirmDialog(2)}
                                variant="outlined"
                                color="secondary"
                                style={{ marginRight: "20px" }}
                            >
                                Delete form
                            </Button>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: "auto",
                                }}
                            ></div>
                        </MuiThemeProvider>
                        </>
                    )}

                    <ConfirmDialog
                        title={
                            "Are you sure you want to delete this form?"
                        }
                        open={openConfirmDialog === 2}
                        setOpen={setOpenConfirmDialog}
                        onConfirm={() => {
                            deleteForm(formId)
                                .then((res) => {
                                    if (
                                        res.status === 204 ||
                                        res.status === 200
                                    ) {
                                        alertSuccess({
                                            title: "Deleted!",
                                            customMessage:
                                                "Form deleted successfully",
                                        });
                                        history.push("/forms");
                                    }
                                })
                                .catch((error) => {
                                    alertError({
                                        error: error,
                                        customMessage:
                                            "Could not delete form.",
                                    });
                                });
                        }}
                    />
                    <ConfirmDialog
                        title={
                            "Are you sure you want to delete the question?"
                        }
                        open={openConfirmDialog === 3}
                        setOpen={setOpenConfirmDialog}
                        onConfirm={() => {
                            deleteQuestion(selectedDeleteQuestion);
                        }}
                    />
                </div>
                </CardHeader>
            <CardBody>
                <Row> 
                    <Col>
                    <TextField
                        id={`name`}
                        label="Name"
                        onChange={handleChange('name')}
                        value={form?.name}
                        margin="normal"
                        variant="standard"
                        type="text"
                    />
                    </Col>
                </Row>
                <br />
                <TableHeader 
                    title="Questions"
                    onClick={() => {
                        setOpenEditQuestion(true);
                        setSelectedQuestion(null);
                    }}
                    numberCol={2} 
                />
                {!refresh && <Table data={getDataQuestions(form)} columns={questionColumns}/>}
                <EditQuestionDialog 
                    open={openEditQuestion}
                    setOpen={setOpenEditQuestion}
                    data={selectedQuestion}
                    onSave={(question, type) => {
                        let array = [], element = "";
                        switch(type) {
                            case "TEXT": array = (form?.texts || []); element= "texts"; break;
                            case "YES/NO": array = (form?.yesNo || []); element= "yesNo"; break;
                            case "TEST": array = (form?.tests || []); element= "tests"; break;
                            default: return;
                        }

                        let newForm = {...form};
                        const index = array?.findIndex((x) => x.number === question?.number);
                        if (index !== -1) newForm[element][index] = question;
                        else newForm[element].push({...question, number: getTotalSize(form) + 1});
                        
                        setForm({...newForm});
                        setRefresh(true);
                    }}
                />
            </CardBody>
        </Card>
    )
}