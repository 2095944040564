import { API, authClient } from '../index'

export const login = (email, password) => {
  const body = { email, password }
  return authClient().post(`${API}/user/signinAdmin`, body)
}

export const register = (body) => {
  return authClient().post(`${API}/user/signup`, body)
}

export const logout = () => {
  return authClient().get(`${API}/user/logout`)
}

export const getCredentials = () => {
  return authClient().get(`${API}/user/credentials`)
}

export const refreshTokens = () => {
  return authClient().get(`${API}/user/refresh`)
}

export const forgotPassword = (credentials) => {
  return authClient().post(`${API}/user/forgot-password-admin`, credentials)
}

export const resetPassword = ({ password, id }) => {
	const body = { password }
  return authClient().put(`${API}/user/forgot-password/${id}`, body)
}

export const verifyEmail = ({ token, password }) => {
  const body = { token, password };
  return authClient().put(`${API}/user/verify-email`, body);
}