export const typeOptions = ["Fuelling products", "Complements"];
export const textureOptions = ["Liquid", "Semisolid", "Solid", "Others"];
export const flavourOptions = ["Sweet", "Salty", "Neutral"];

export const typeOptionsN = ["None", "Fuelling products", "Complements"];
export const textureOptionsN = ["None", "Liquid", "Semisolid", "Solid", "Others"];
export const flavourOptionsN = ["None", "Sweet", "Salty", "Neutral"];

export function isLiquid(food) {
    if (food?.texture === "Liquid") return true;
    return false;
}

export function getNts(food, ml, uds) {
    let choG = 0;
    let sodiumMg = 0;
    let sodiumMgL = 0;
    let waterMl = 0;
    let caffeine = 0;
    const w = food?.weight || 1;

    if (isLiquid(food)) {
        waterMl = food?.water * ml / w || 0;
        choG = food?.carbohydrates * ml / w;
        sodiumMg = food?.sodium * ml / w;
        caffeine = food?.caffeine * ml / w;
    }
    else {
        const ud = uds ?? 1;
        waterMl += food?.water * ud;
        choG += food?.carbohydrates * ud;
        sodiumMg += food?.sodium * ud;
        caffeine += food?.caffeine * ud;
    }

    return { waterMl, choG, sodiumMg, sodiumMgL, caffeine };
}