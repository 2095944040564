import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { deleteSpecificsTramsXUser, getTramsCompetitionUser } from "../../../../api/tramXuser";
import _ from 'lodash';
import { getDataBlocks } from "../../../../utils/structuresCalcs";
import { getActiveUserStructuresByUserId } from "../../../../api/userStructures";
import { InfoPatientCompetition } from "../../../../utils/competitionCalcs";
import { DietInfo, NotesInfo } from "../../../../utils/dietUtils";
import DietTabLock from "../../../modules/ContentManager/patients/DietTabLock";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export default function EditPatientDietDialog(props) {
	const { patient, title, data, open, setOpen, onSave, userId, 
		competitions, userCompetitions, 
		changes, setChanges, tramsXuser, setTramsXuser, date,
		setOpenEditBlockDialog, setSelectedBlock, setIsMeal,
		blocks, setBlocks, userDiet, setUserDiet, structure, 
		setStructure, saveDiet, updateSt, tabNumber } = props;

	const [competition, setCompetition] = useState(getEmptyCompetition());
    const [competitionOptions, setCompetitionOptions] = useState([]);

    const [openEditTramUserDialog, setOpenEditTramUserDialog] = useState(false);

    const [structures, setStructures] = useState([]);
    const [structuresOptions, setStructuresOptions] = useState([]);


    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [selectedStructure, setSelectedStructure] = useState(null);
    const [structureHasChanged, setStructureHasChanged] = useState(false);

	const [dataBlocks, setDataBlocks] = useState([]);
	const [dataNt, setDataNt] = useState({kcals: 0, carbohydrates: 0, proteins: 0, fats: 0, fiber: 0});

	const [selectedDeleteBlock, setSelectedDeleteBlock] = useState(-1);

	const [refresh, setRefresh] = useState(false);
	
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	function getEmptyCompetition() {
		return {
			_id: null,
			competitionId: null,
			competitionName: null,
			totalTime: '',
			mediumPace: '',
			arrivalTime: '',
			date: date?.date?.toISOString(),
			finished: false
		};
	}

	function getEmptyTram() {
		return {
			_id: undefined,
			numTram: '',
			name: '',
			idClient: userId,
			idCompetition: competition?.competitionId,
			foods: [],
			extrInfo: ''
		};
	}

	useEffect(() => {
		if (!data) {
			setUserDiet(null);
			setStructure(null);
			setBlocks([]);
		}
		else {
			const dataAux = _.cloneDeep(data);
			setUserDiet(dataAux);
			setStructure(dataAux?.diet);
			setBlocks(dataAux?.diet?.blocks);
		}
		disableLoadingData();
	}, [data])

	useEffect(() => {
		if (date) {
			const dt = date.date.toISOString();
			const comp = userCompetitions?.competitions.find((x) => x.date === dt);
			if (comp) {
				setCompetition(comp);
				actualizeTramsData(comp);
			}
			else setCompetition(getEmptyCompetition());
		}
	}, [date])

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

    useEffect(() => {
        if (structures?.length === 0 && userId)
            getActiveUserStructuresByUserId(userId)
                .then((res) => {
                    if (res.status === 200) {
                        if (!res?.data?.length === 0) setStructures(res.data);
                        actualizeStructuresOptions(res.data);
                    }
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get structures.",
                    });
                });
    }, [structures])

    useEffect(() => {
        if (competitions?.length !== 0) actualizeCompetitionOptions(competitions)
    }, [competitions])

    useEffect(() => {
        if (structure) {
            setStructureHasChanged(true);
            setChanges(changes+1);
        }
    }, [structure])

	useEffect(() => {
		if (blocks) getData(blocks)
	}, [blocks])

	function actualizeCompetition(selected) {
		if (competition?.competitionId && competition?.competitionId !== -1) { 
			deleteSpecificsTramsXUser(competition?.competitionId, userId, competition.date)
				.then((res) => {
					if (res.status === 204) {

					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "There was a problem actualizing the trams information"
					})
				})
		}
		if (userId) actualizeTramsData(selected);
		const newCompetition = {
			...competition,
			competitionId: selected.competitionId,
			competitionName: selected.competitionName
		}
		setCompetition(newCompetition);
		actualizeTramsData(newCompetition);
		setChanges(changes+1);
		setRefresh(true)
	}

    function actualizeTramsData(competition) {
        if (userId && date?.dateStr && competition?.competitionId && competition?.competitionId !== -1) 
			getTramsCompetitionUser(competition?.competitionId, userId, date.dateStr)
				.then((res) => {
					if (res.status === 200) {
						setTramsXuser(res.data);
						disableLoadingData();
					}
				})
				.catch((error) => {
					console.log("No hay tramos")
				});
    }

	function actualizeCompetitionOptions(competitions) {
        let data = [];

		data.push({
			competitionId: -1,
			competitionName: "None"
		})

        for (let i = 0; i < competitions?.length; ++i) {
            let elem = {};
            const act = competitions[i];

            elem.competitionId = act._id;
            elem.competitionName = act.fullName.es;

            data.push(elem);
        }

        setCompetitionOptions(data);
    }

	function actualizeStructuresOptions(structures) {
		let data = [];

		for (let i = 0; i < structures?.length; ++i) {
			let elem = {}
			const act = structures[i];

			elem._id = act._id;
			elem.name = act.fullName.es;

			data.push(elem);
		}

		setStructuresOptions(data);
	}

	async function getData(blocks) {
		const dataBlocks = await getDataBlocks(blocks, patient);
		setDataNt({
			kcals: dataBlocks.kcals,
			proteins: dataBlocks.proteins,
			carbohydrates: dataBlocks.carbohydrates,
			fats: dataBlocks.fats,
			fiber: dataBlocks.fiber
		})
		setDataBlocks(dataBlocks.data);
		setRefresh(true);
	}

	const renderDietTabContent = [
		<>
			<br />
			<DietInfo
			openConfirmDialog={openConfirmDialog}
			setOpenConfirmDialog={setOpenConfirmDialog}
			selectedStructure={selectedStructure}
			setRefresh={setRefresh}
			refresh={refresh}
			userDiet={userDiet}
			setUserDiet={setUserDiet}
			blocks={blocks}
			setBlocks={setBlocks}
			setOpenEditBlockDialog={setOpenEditBlockDialog}
			setSelectedBlock={setSelectedBlock}
			structure={structure}
			setStructure={setStructure}
			setIsMeal={setIsMeal}
			setSelectedDeleteBlock={setSelectedDeleteBlock}
			saveDiet={saveDiet}
			disableLoadingData={disableLoadingData}
			dataNt={dataNt}
			setDataNt={setDataNt}
			dataBlocks={dataBlocks}
			setDataBlocks={setDataBlocks}
			updateSt={updateSt}
			setChanges={setChanges}
			selectedDeleteBlock={selectedDeleteBlock}
			structuresOptions={structuresOptions}
			structureHasChanged={structureHasChanged}
			setSelectedStructure={setSelectedStructure}
			/>
		</>,
		<>
			<br />
			<InfoPatientCompetition 
			userId={userId}
			openEditTramUserDialog={openEditTramUserDialog}
			setOpenEditTramUserDialog={setOpenEditTramUserDialog}
			competition={competition}
			setCompetition={setCompetition}
			competitions={competitions}
			tramsXuser={tramsXuser}
			setTramsXuser={setTramsXuser}
			date={date}
			inDiet={true}
			/>
		</>,
		<>
			<br />
			<NotesInfo 
			userDiet={userDiet}
			setUserDiet={setUserDiet}
			setRefresh={setRefresh}
			/>
		</>,
	]

    if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<Dialog disableBackdropClick={true}
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				maxWidth="lg"
				aria-labelledby="table-dialog"
			>
				<DialogTitle id="table-dialog">{title}</DialogTitle>
				<DialogContent>
					<DietTabLock 
						dietTabContent={renderDietTabContent}
						number={tabNumber}
					/>
				</DialogContent>
				<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						const dataAux = _.cloneDeep(data);
						setUserDiet(dataAux);
						setDataNt({kcals: 0, carbohydrates: 0, proteins: 0, fats: 0, fiber: 0});
						setStructure(dataAux?.diet);
						setBlocks(dataAux?.diet?.blocks);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						//setOpen(false);
						onSave(userDiet, tramsXuser, competition);
						setDataNt({kcals: 0, carbohydrates: 0, proteins: 0, fats: 0, fiber: 0});
						//setUserDiet(null);
					}}
				>
					Save
				</Button>
				</DialogActions>
			</Dialog>
		);
}
