import { getFoodById } from "../api/food/index"
import { deleteFoodPlateStructure } from "../api/foodPlateStructure";
import { formatFloat } from "./helpers";
import { alertError } from "./logger";
import { calcTEEDay, calcTMBMlkgmin } from "./patientCalcs";

export function foodCalcs(food) {
    const grams = parseFloat(food?.grams) || 0; 

    return {
        kcals: food?.food?.kcals*grams/100,
        proteins: food?.food?.proteins*grams/100,
		carbohydrates: food?.food?.carbohydrates*grams/100,
		fats: food?.food?.fats*grams/100,
		fiber: food?.food?.fiber*grams/100
    }
}

export function valueCalc(value, grams) {
    return parseFloat(value)*parseFloat(grams)/100;
}

export async function recipeCalcs(recipe) {
    const foodsIds = [...recipe.foods];

    let datt = [];
    let foods = [];

    for (let i = 0; i < foodsIds?.length; ++i) {
        const promise = getFoodById(foodsIds[i].foodId)
            .then((res) => {
                if (res.status === 200) {
                    const food = res.data;
                    foods.push({...food, grams: foodsIds[i].grams});
                }   
            })
            .catch((error) => {
                console.log(error)
            })
        datt.push(promise);
    }

    let nutritionalValues = {
        kcals: 0,
        proteins: 0,
        carbohydrates: 0,
        fats: 0,
        fiber: 0
    }
    
    await Promise.all(datt)
        .then(() => {
            nutritionalValues = foods.reduce((values, food) => {
                const gr = food?.grams;
                return {
                    kcals: parseFloat(values.kcals + valueCalc(food?.kcals, gr)),
                    proteins: parseFloat(values.proteins + valueCalc(food?.proteins, gr)),
                    carbohydrates: parseFloat(values.carbohydrates + valueCalc(food?.carbohydrates, gr)),
                    fats: parseFloat(values.fats + valueCalc(food?.fats, gr)),
                    fiber: parseFloat(values.fiber + valueCalc(food?.fiber, gr))
                    
                };
            }, {kcals: 0, proteins: 0, carbohydrates: 0, fats: 0, fiber: 0})
        })

    return nutritionalValues;
}

const emptyNtValues = {
    kcals: 0,
    carbohydrates: 0,
    fats: 0,
    fiber: 0,
    proteins: 0
}

export function runCalcs(intensity) {
    const nulls = { speed: null };
    if (!intensity || !intensity?.calculatedValue) return nulls;
    let pace = parseFloat(intensity?.paceMinutes || 0) + 
                parseFloat(intensity?.paceSeconds || 0)/60;
    let dist = parseFloat(intensity?.distance);
    let time = parseFloat(intensity?.time);
    const valueC = parseFloat(intensity?.calculatedValue)

    switch (valueC) {
        case 3: time = pace * dist; break;
        case 2: dist = time / pace; break;
        case 1: pace = time / dist; break;
        default: return nulls;
    }

    const speed = 60/pace;
    return { pace: pace, dist: dist, time: time, speed: speed };
}

function trailRunningAscDesc(asc, desc, dist, time) {

    function firstCalcs(unev1, unev2) {
        if (unev1 === 0 || unev2 === 0 || (unev1+unev2) === 0) return 0;
        const ascD = (unev1 / (unev1 + unev2)) * dist;
        const ascG = ((unev1 / 1000) / ascD) * 100;
        const ascGAF = -(0.0000004 * ascG*ascG*ascG*ascG) - 
            (0.000003 * (ascG*ascG*ascG)) + (0.0019 * ascG*ascG) +
            (0.0301 * ascG) + 1;
        const actAD = ascD * ascGAF;
        return actAD;
    }

    const actAD = firstCalcs(asc, desc);
    const descAD = firstCalcs(desc, asc);
    const totalAD = actAD + descAD;
    if (descAD === 0) return 0;
    const gradeAS = time / 60 + totalAD / descAD;
    // console.log(actAD, descAD, totalAD, gradeAS);
    return gradeAS;
}

export function activityCalcs(activity, intensityParams, patient) {
    if (!patient) return {...emptyNtValues};
    let mets = 0, intensity = 0;
    const type = activity?.typeOfActivity;
    switch(type) {
        case "Running":
            intensity = parseFloat(intensityParams?.speed || runCalcs(intensityParams).speed || 0);
            // console.log(intensity)
            mets = (0.0046 * intensity*intensity*intensity ) - 
                (0.1802 * intensity*intensity) + 
                (3.1192 * intensity) - 7.811;
            break;
        case "Cycling":
            intensity = parseFloat(intensityParams?.power || 0);
            mets = (0.0419 * intensity) + 2.2728;
            break;
        case "Trailrunning":
            const asc = parseFloat(intensityParams?.ascent) || 0;
            const desc = parseFloat(intensityParams?.descent) || 0;
            const dist = parseFloat(intensityParams?.distance) || 0;
            const time = parseFloat(intensityParams?.time) || 0;
            intensity = trailRunningAscDesc(asc, desc, dist, time);
            mets = (0.0046 * intensity*intensity*intensity ) - 
                (0.1802 * intensity*intensity) + 
                (3.1192 * intensity) - 7.811;
            break;
        default:
            if (!activity?.intensityType || !activity?.activity) return {...emptyNtValues};
            intensity = parseFloat(activity?.activity[activity?.intensityType] || 0);
            mets = intensity;
            break;
    }

    if (intensity === 0) return {...emptyNtValues};

    const bmr = calcTMBMlkgmin(patient?.tmb, patient?.actualWeight);
    const correctedMets = mets * (3.5 / bmr);
    let adjustedMets = 0;
    if (mets <= 1) adjustedMets = mets;
    else if (mets >= 6) adjustedMets = correctedMets;
    else adjustedMets = mets + ((correctedMets - mets) * (mets - 1))/5;

    const kcalMin = 0.0175 * adjustedMets * patient?.actualWeight;
    const kcals = kcalMin * parseFloat(intensityParams?.time);
    //METS 11.6194, 
    // console.log(intensity, mets, correctedMets, adjustedMets, kcalMin, kcals)
    // console.log(activity, intensityParams, patient?.tmbmlkgmin, patient?.actualWeight)
    const zValue = intensityParams?.zValue;
    return {
        kcals: kcals,
        carbohydrates: zValue ? calcZValue(zValue, kcals, "carbohydrates") : 0,
        fiber: 0,
        fats: zValue ? calcZValue(zValue, kcals, "fats") : 0,
        proteins: 0,
        time: 0
    };
}

async function blockRecipeFoodCalcs(nt, block) {
    let nutritionalValues = {...nt};
    if (block?.intake?.recipes?.length > 0) {
        const recipeKcals = await Promise.all(
            block.intake.recipes.map(async (recipe) => {
            try {
                const res = !recipe?.extraMeal ? await recipeCalcs(recipe.recipe) :
                        {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
                return res;
            } catch (error) {
                console.log(error);
                return 0;
            }
            })
        );
        nutritionalValues = recipeKcals.reduce((total, nt) => {
            return sumValues(total, nt)}, {
            kcals: nutritionalValues.kcals, 
            carbohydrates: nutritionalValues.carbohydrates, 
            fiber: nutritionalValues.fiber, 
            fats: nutritionalValues.fats, 
            proteins: nutritionalValues.proteins
            
        });
    }
    if (block?.intake?.foods?.length > 0) nutritionalValues = block.intake?.foods?.reduce((total, food) => {
        if (!food?.extraMeal) {
            const nt = foodCalcs(food);
            return sumValues(total, nt)
        }
        else return total;
        }, {
            kcals: nutritionalValues.kcals, 
            carbohydrates: nutritionalValues.carbohydrates, 
            fiber: nutritionalValues.fiber, 
            fats: nutritionalValues.fats, 
            proteins: nutritionalValues.proteins
    });

    return {...nutritionalValues, 
        isOneFood: (
            nutritionalValues.kcals ||
            nutritionalValues.carbohydrates ||
            nutritionalValues.fiber ||
            nutritionalValues.fats || 
            nutritionalValues.proteins
        )
    };
}

const cubeValues = {
    cubeVegetables: {kcals: 28.5, carbohydrates: 4, fiber: 0, fats: 0.5, proteins: 2},
    cubeStarches: {kcals: 68.5, carbohydrates: 14, fiber: 0, fats: 0.5, proteins: 2},
    cubeProteins: {kcals: 46, carbohydrates: 0, fiber: 0, fats: 2, proteins: 7},
    cubeFats: {kcals: 45, carbohydrates: 0, fiber: 0, fats: 5, proteins: 0}
}

function cubeCalc(value, name, total) {
    if (value <= 0) return total;
    let cub = {...cubeValues[name]};
    cub.kcals = value * cub.kcals;
    cub.carbohydrates *= value;
    cub.fiber *= value;
    cub.fats *= value;
    cub.proteins = value * cub.proteins;
    return sumValues(total, cub);
}

export async function errorCalc(block) {
    let nutritionalValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
    let errorValues = {kcals: false, carbohydrates: false, fiber: false, fats: false, proteins: false};
    
    // let nutritionalCubeValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
    // const intake = block.intake;
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeVegetables) || 0, "cubeVegetables", nutritionalCubeValues);
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeStarches) || 0, "cubeStarches", nutritionalCubeValues);
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeProteins) || 0, "cubeProteins", nutritionalCubeValues);
    // nutritionalCubeValues = cubeCalc(parseFloat(intake?.cubeFats) || 0, "cubeFats", nutritionalCubeValues);

    const kcals = block?.kcals;//nutritionalCubeValues?.kcals;
    const carbohydrates = block?.carbohydrates;
    const fiber = block?.fiber;
    const fats = block?.fats;
    const proteins = block?.proteins;
    
    if (block?.intake?.recipes?.length > 0 || block?.intake?.foods?.length > 0) {
        nutritionalValues = await blockRecipeFoodCalcs(nutritionalValues, block);
        
        if (nutritionalValues.isOneFood) {
            if (Math.abs(kcals - nutritionalValues.kcals) >= 20) errorValues.kcals = true;
            if (Math.abs(carbohydrates - nutritionalValues.carbohydrates) >= 20) errorValues.carbohydrates = true;
            if (Math.abs(fats - nutritionalValues.fats) >= 20) errorValues.fats = true;
            if (Math.abs(proteins - nutritionalValues.proteins) >= 20) errorValues.proteins = true;
            // if (Math.abs(fiber - nutritionalValues.fiber) >= 20) return true;
        }
    }

    return errorValues;
}

export function getType(block) {
    if (!block?.isIntake) return "Exercise";
    if(blockIsMain(block)) return "Main";
    return "Snack";
}

export function blockIsMain(block) {
    if (!block?.isIntake) return false;
    const intake = block?.intake;
    if (intake?.isSnack === '0' || !intake?.isSnack) return true;
    return false;
}

export async function blockCalc(block, patient) {
    let nutritionalValues = {kcals: 0, carbohydrates: 0, fiber: 0, fats: 0, proteins: 0};
    let requeriments = 0, energetics = 0, exerciseTime = 0;
    async function calcFoodAndRecipes(isMain) {
        if (block?.intake?.recipes?.length > 0) {
            const recipeKcals = await Promise.all(
                block.intake.recipes.map(async (recipe) => {
                try {
                    const res = await recipeCalcs(recipe.recipe);
                    if (isMain && !recipe?.extraMeal) return nutritionalValues;
                    return res;
                } catch (error) {
                    console.log(error);
                    return 0;
                }
                })
            );
            nutritionalValues = recipeKcals.reduce((total, nt) => {
                return sumValues(total, nt)}, {
                kcals: nutritionalValues.kcals, 
                carbohydrates: nutritionalValues.carbohydrates, 
                fiber: nutritionalValues.fiber, 
                fats: nutritionalValues.fats, 
                proteins: nutritionalValues.proteins
                
            });
        }
        if (block?.intake?.foods?.length > 0) {
            nutritionalValues = block.intake?.foods?.reduce((total, food) => {
                if (isMain && !food.extraMeal) return total;
                const nt = foodCalcs(food);
                return sumValues(total, nt)}, {
                    kcals: nutritionalValues.kcals, 
                    carbohydrates: nutritionalValues.carbohydrates, 
                    fiber: nutritionalValues.fiber, 
                    fats: nutritionalValues.fats, 
                    proteins: nutritionalValues.proteins
                });
            }
    }
    if (block?.isIntake) {
        const intake = block?.intake;
        const isMain = blockIsMain(block);
        if (isMain) {
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeVegetables) || 0, "cubeVegetables", nutritionalValues);
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeStarches) || 0, "cubeStarches", nutritionalValues);
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeProteins) || 0, "cubeProteins", nutritionalValues);
            nutritionalValues = cubeCalc(parseFloat(intake?.cubeFats) || 0, "cubeFats", nutritionalValues);
        }
        await calcFoodAndRecipes(isMain)
    } else {
        const activity = block?.exercise?.activities;
        if (activity?.intensities?.length > 0) {
            nutritionalValues = activity?.intensities?.reduce((total, intensity) => {
                const nt = activityCalcs(activity, intensity, patient);
                exerciseTime += parseFloat(intensity?.time || 0);
                return sumValues(total, nt)
                }, {
                    kcals: nutritionalValues.kcals, 
                    carbohydrates: nutritionalValues.carbohydrates, 
                    fiber: nutritionalValues.fiber, 
                    fats: nutritionalValues.fats, 
                    proteins: nutritionalValues.proteins
                }); 
        }
    }

    return {...nutritionalValues, 
        requeriments: requeriments, energetics: energetics,
        exerciseTime: exerciseTime
    };
}

function sumValues(total, nt) {
    return {
        kcals: total.kcals + nt.kcals, 
        carbohydrates: total.carbohydrates + nt.carbohydrates, 
        fiber: total.fiber + nt.fiber, 
        fats: total.fats + nt.fats, 
        proteins: total.proteins + nt.proteins
    }
}

export function reCalcNutritionalValues(blocks) {
    let data = {};
    
    let kcals = 0;
    let carbohydrates = 0;
    let protein = 0;
    let fats = 0;
    let fiber = 0;
    let fatskg = 0;
    let fatspercent = 0;
    
    for (let i = 0; i < blocks?.length; ++i) {
        const block = blocks[i];
        const blockForw = (i+1 !== blocks?.length) ? blocks[i+1] : null;
        if (block?.isIntake) {
            if (block?.name === blockForw?.name && !blockIsMain(block) && !blockIsMain(blockForw)) {
                let kcalsSum = block?.kcals;
                let carbohydratesSum = block?.carbohydrates;
                let proteinSum = block?.proteins;
                let fatsSum = block?.fats;
                let fiberSum = block?.fiber;
                let counter = 1;

                const name = block.name;
                for (let j = i+1; j < blocks?.length; ++j) {
                    const actBlock = blocks[j];
                    if (actBlock?.name === name && !blockIsMain(actBlock) && !blockIsMain(actBlock)) {
                        kcalsSum += actBlock.kcals;
                        carbohydratesSum += actBlock.carbohydrates;
                        proteinSum += actBlock.proteins;
                        fatsSum += actBlock.fats;
                        fiberSum += actBlock.fiber;
                        counter++;
                    } else break;
                }
                
                kcals += kcalsSum/counter;
                carbohydrates += carbohydratesSum/counter;
                protein += proteinSum/counter;
                fats += fatsSum/counter;
                fiber += fiberSum/counter;
                i += counter-1;
            } else {
                kcals += block?.kcals;
                carbohydrates += block?.carbohydrates;
                protein += block?.proteins;
                fats += block?.fats;
                fiber += block?.fiber;
            }
        } else {
            kcals = kcals - block?.kcals;
        }
    }

    data.kcals = kcals;
    data.carbohydrates = carbohydrates;
    data.protein = protein;
    data.fats = fats;
    data.fiber = fiber;
    data.fatskg = fatskg;
    data.fatspercent = fatspercent;
    return data;
}

export async function getDataBlocks(blocks, patient) {

    function sortByNumber(a, b) {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
    }

    function formatNameIntake(block, blocks) {
        const name = block?.name;
        const isSnack = (block?.intake?.isSnack);
        if (!isSnack) return name;
        const blockIndex = blocks?.findIndex((x) => x.number === block.number);
        let sumDown = 0, sumUp = 0;
        for (let i = blockIndex-1; i >= 0; i--) { 
          const actB = blocks[i];	
          if (actB?.name === name && actB?.intake?.isSnack) sumDown++; 
          else break;
        }
        for (let i = blockIndex+1; i < blocks.length; i++) { 
          const actB = blocks[i];	
          if (actB?.name === name && actB?.intake?.isSnack) sumUp++; 
          else break;
        }
        if ((sumDown + sumUp) > 0) {
          if (sumUp === 0 && sumDown%3 === 0) return name;
          return `${block.name} (Option ${(sumDown%3)+1})`;
        }
        return name;
      }

    function getInformation(block, calcs, blocks) {
        let elem = {};

        elem._id = block._id;
        elem.number = block.number;

        if (block?.isIntake) elem.name = formatNameIntake(block, blocks);
        else elem.name = block.name;

        const tempDiv = document.createElement("div");
        tempDiv.style.display = "none";
        tempDiv.innerHTML = block?.description || "There is no description";
        const plainText = tempDiv.textContent || tempDiv.innerText;
        elem.description = plainText;

        elem.kcals = calcs?.kcals;
        elem.carbohydrates = calcs?.carbohydrates;
        elem.proteins = calcs?.proteins;
        elem.fats = calcs?.fats;
        elem.fiber = calcs?.fiber;
        elem.intake = (block.isIntake ? block.intake : block.exercise);

        return {...elem};
    }

    let data = [];

    let kcals = 0;
    let proteins = 0;
    let carbohydrates = 0;
    let fats = 0;
    let fiber = 0;
    let energetics = 0;
    let requeriments = 0;
    let totalExerciseTime = 0, totalBurnedKcals = 0;

    for (let i = 0; i < blocks.length; ++i) {
        let elem = {};
        const block = {...blocks[i]};
        
        const calcs = await blockCalc(block, patient);
        elem = getInformation(block, calcs, blocks);

        const blockForw = (i+1 !== blocks?.length) ? blocks[i+1] : null;
        if (block?.isIntake) {
            if (block?.name === blockForw?.name && !blockIsMain(block) && !blockIsMain(blockForw)) {
                let kcalsSum = elem?.kcals;
                let carbohydratesSum = elem?.carbohydrates;
                let proteinSum = elem?.proteins;
                let fatsSum = elem?.fats;
                let fiberSum = elem?.fiber;
                let energeticsSum = elem?.kcals;
                let counter = 1;

                const name = block.name;
                for (let j = i+1; j < blocks?.length; ++j) {
                    const actBlock = blocks[j];
                    if (actBlock?.name === name && !blockIsMain(actBlock) && !blockIsMain(actBlock)) {
                        const calcsAct = await blockCalc(actBlock, patient);
                        const elem2 = getInformation(actBlock, calcsAct, blocks);

                        kcalsSum += calcsAct.kcals;
                        carbohydratesSum += calcsAct.carbohydrates;
                        proteinSum += calcsAct.proteins;
                        fatsSum += calcsAct.fats;
                        fiberSum += calcsAct.fiber;
                        energeticsSum += calcsAct.energetics;

                        data.push(elem2);
                        counter++;
                    } else break;
                }
                
                kcals += kcalsSum/counter;
                carbohydrates += carbohydratesSum/counter;
                proteins += proteinSum/counter;
                fats += fatsSum/counter;
                fiber += fiberSum/counter;
                i += counter-1;
            } else {
                kcals += calcs?.kcals;
                carbohydrates += calcs?.carbohydrates;
                proteins += calcs?.proteins;
                fats += calcs?.fats;
                fiber += calcs?.fiber;
            }
        } else {
            kcals = kcals - calcs?.kcals;
            totalBurnedKcals += parseFloat(calcs?.kcals);
            totalExerciseTime += calcExerciseMinutes(block);
        }
     
        data.push(elem);
    }

    requeriments = calcTEEDay(
        patient?.gerkcalh || 0, 
        (totalExerciseTime || 0) / 60, 
        totalBurnedKcals || 0
    )

    data.sort(sortByNumber);
    return {
        data: data,
        kcals: kcals,
        proteins: proteins,
        carbohydrates: carbohydrates,
        fats: fats,
        fiber: fiber,
        energetics: energetics,
        requeriments: requeriments
    };
}

export function calcExerciseMinutes(block) {
    let time = 0;
    const intensities = block?.exercise?.activities?.intensities;
    for (let i = 0; i < intensities?.length; ++i) 
        time += parseFloat(intensities[i]?.time || 0);

    return time;
}

export const mainColor = '#ABF5FF';
export const snackColor = '#EBF6DE';
export const activityColor = '#FFFFA3';

export function styleFormat(block) {
    return (block?.isIntake? (!block?.intake.isSnack || block?.intake?.isSnack === '0')?
        { //MAIN
            backgroundColor: mainColor
        } : 
        { //SNACK
            backgroundColor: snackColor

        } : 
        { //ACTIVITY
            backgroundColor: activityColor
        });
}

export async function deleteStructureRecipe(recipe) {
    if (!recipe?._id) return true;
    const res = await deleteFoodPlateStructure(recipe._id)
        .catch((error) => {
            alertError({
                error: error,
                customMessage: "Could not delete recipe"
            })
            return false;
        })
    
    if (res) return true;
    return false;
}

export async function deleteBlockRecipesFromBD(structure, index) {
    const block = structure?.blocks?.find((x) => x.number === index);

    if (block?.isIntake) {
        if (block?.intake?.recipes?.length > 0) {
            const recipes = [...block?.intake?.recipes];
            for (let i = 0; i < recipes?.length; ++i) {
                const recipe = recipes[i];
                const res = await deleteStructureRecipe(recipe?.recipe);
                if (!res) return false;
            }
        }
    }

    return true;
}

export async function deleteAllBlocksRecipesFromBD(structure) {
    for (let i = 0; i < structure?.blocks?.length; ++i) {
        const actB = structure?.blocks[i];
        const res = await deleteBlockRecipesFromBD(structure, actB.number);
        if (!res) return false;
    }
    return true;
}

export function getDataBlocksTable(blocks, patient) {
    function sortByNumber(a, b) {
        if (a.number < b.number) return -1;
        if (a.number > b.number) return 1;
        return 0;
    }

    let data = [];

    for (let i = 0; i < blocks.length; ++i) {
        let elem = {};
        const block = blocks[i];

        elem._id = block._id;
        elem.number = block.number;
        elem.name = block.name;

        const tempDiv = document.createElement("div");
        tempDiv.style.display = "none";
        tempDiv.innerHTML = block?.description || "There is no description";
        const plainText = tempDiv.textContent || tempDiv.innerText;
        elem.description = plainText;

        elem.kcals = block?.kcals || 0;
        elem.carbohydrates = block?.carbohydrates || 0;
        elem.proteins = block?.proteins || 0;
        elem.carbohydratesG = calcUnitsNt(block?.carbohydrates, "g/kg", 
            "carbohydrates", block?.kcals, patient?.selectedWeight || 1);
        elem.proteinsG = calcUnitsNt(block?.proteins, "g/kg", 
            "proteins", block?.kcals, patient?.selectedWeight || 1);
        elem.fats = block?.fats || 0;
        elem.fiber = block?.fiber || 0;
        elem.intake = block?.intake || [];
        elem.type = getType(block);
        elem.totalGChoH = block?.exercise?.activities?.totalGChoH;
        elem.totalMlh = block?.exercise?.activities?.totalMlh;

        data.push(elem);
    }

    data.sort(sortByNumber);

    return data;
}

export function calcUnitsNt(value, type, nt, kcals, weight) {
    const number = parseFloat(value);
    const numKcals = parseFloat(kcals);
    if (type === "g/kg") return (number / parseFloat(weight)).toFixed(1);
    else if (type === "%") {
        if (nt === "carbohydrates" || nt === "proteins") 
            return ((number * 4 / numKcals) * 100).toFixed(1);
        else return ((number * 9 / numKcals) * 100).toFixed(1);
    }
    else return formatFloat(number);
}

function calcReqPro(tee, selectedW, exerciseT) {
    let values = {min: 0, max: 0};

    const gKg = (tee * 0.25 / 4) / selectedW;
    if (gKg < 1.8) values.max = selectedW * 1.8;
    else if (gKg > 3) values.max = selectedW * 3;
    else values.max = gKg * selectedW;

    switch (exerciseT) {
        case 0: values.min = selectedW * 1.2; break;
        default: values.min = selectedW * 1.6; break;
    }

    values.min = values.min;
    values.max = values.max;

    return values;
}

function calcReqFat(teeRest, selectedW, burnedFats, tee) {
    let values = {min: 0, max: 0};

    const gKg = (teeRest * 0.35 / 9) / selectedW;
    let value1 = 0;
    if (gKg > 2) value1 = selectedW * 2;
    else if (gKg < 0.6) value1 = selectedW * 0.6;
    else value1 = gKg * selectedW;
    value1 = (value1 + burnedFats) / selectedW;
    if (value1 > 3) values.max = selectedW * 3;
    else values.max = value1 * selectedW;

    const perc = (selectedW * 0.6 * 9 / tee) * 100;
    if (perc > 20) values.min = selectedW * 0.6;
    else {
        if (((tee * 0.2 / 9) / selectedW) > 1) values.min = selectedW;
        else values.min =  tee * 0.2 / 9
    }
    
    values.min = values.min;
    values.max = values.max;

    return values;
}

function calcReqCho(reqPro, reqFat, tee) {
    let values = {min: 0, max: 0};
    values.max = tee - (reqPro.min * 4 + reqFat.min * 9);
    values.min = tee - (reqPro.max * 4 + reqFat.max * 9);

    values.max = values.max / 4;
    values.min = values.min / 4;
    return values;
}

export function calcRequeriments(TEE, patient, exerciseTime, burnedFats) {
    let req = {reqCho: {min: 0, max: 0}, reqPro: {min: 0, max: 0}, reqFat: {min: 0, max: 0}};

    const selectedW = parseFloat(patient?.selectedWeight);
    const teeRest = parseFloat(patient?.teeRest);
    const tee = parseFloat(TEE);
    req.reqPro = calcReqPro(tee, selectedW, parseFloat(exerciseTime));
    req.reqFat = calcReqFat(teeRest, selectedW, parseFloat(burnedFats), tee);
    req.reqCho = calcReqCho(req.reqPro, req.reqFat, tee);

    return req;
}

export const activityTypes = ["Running", "Cycling", "Trailrunning", "Others"];
export const intensityTypes = ["Moderate", "Vigorous", "Light"];
export const zValues = [
    {
        zValue: "1", name: "Very easy", choP: 33.4, fatP: 66.6, 
        description: "No tinc cap dificultat en mantenir una conversa (igual que en repòs)."
    },
    {
        zValue: "2", name: "Endurance", choP: 50.7, fatP: 49.3, 
        description: "Puc mantenir una conversa pero he d'anar fent algunes pauses per agafar una mica d'aire."
    },
    {
        zValue: "3", name: "Tempo", choP: 67.5, fatP: 32.5, 
        description: "Em costa manternir una conversació fluida, però puc dir alguna frase."
    },
    {
        zValue: "4", name: "Interval training", choP: 84, fatP: 18, 
        description: "No puc mantenir cap conversa, només puc pronunciar paraules aïllades."
    },
    {
        zValue: "5", name: "Max effort", choP: 100, fatP: 0, 
        description: "No puc dir ni paraula, ritme màxim."
    },
]

export function calcZValue(zValue, kcals, type) {
    const num = parseFloat(kcals);
    const zVals = zValues.find((x) => x.zValue === zValue);
    if (zVals === undefined) return;
    if (type === "carbohydrates") return (num*(zVals.choP/100)/4)
    return (num*(zVals.fatP/100)/9);
}

export function calcTotalG(gramsHour, intensities) {
    let time = 0;
    for (let i = 0; i < intensities?.length; ++i) time += parseFloat(intensities[i].time);
    return (parseFloat(time * gramsHour / 60).toFixed(1));
}

export function calcTotalGHours(totalGrams, intensities) {
    let time = 0;
    for (let i = 0; i < intensities?.length; ++i) time += parseFloat(intensities[i].time);
    return (parseFloat(totalGrams / (time / 60)).toFixed(1));
}

export function calcTotalGrams(block) {
    return calcTotalG(block?.exercise?.activities?.gramsHour, 
        block?.exercise?.activities?.intensities || []);
}

export function calcGramsHour(block) {
    return calcTotalGHours(block?.exercise?.activities?.totalGrams, 
        block?.exercise?.activities?.intensities);
}

export function calcTotalGCho(block) {
    return calcTotalG(block?.exercise?.activities?.totalGChoH, 
        block?.exercise?.activities?.intensities || []);
}

export function calcTotalGChoH(block) {
    return calcTotalGHours(block?.exercise?.activities?.totalGCho, 
        block?.exercise?.activities?.intensities);
}

export function calcTotalMl(block) {
    return calcTotalG(block?.exercise?.activities?.totalMlh, 
        block?.exercise?.activities?.intensities || []);
}

export function calcTotalMlH(block) {
    return calcTotalGHours(block?.exercise?.activities?.totalMl, 
        block?.exercise?.activities?.intensities);
}

export function intakeFormat(row, type) {
    let description = ``;
    const foods = row?.intake?.foods || [];
    for (let i = 0; i < foods.length; ++i) {
        const actF = foods[i];
        const name = actF?.food?.fullName?.es;
        if (type === "Main") {
            if (actF?.extraMeal) 
                description += `${description !== `` ? ", " : ""}${name}`;
        }
        else description += `${description !== `` ? ", " : ""}${name}`;
    }
    const recipes = row?.intake?.recipes || [];
    for (let i = 0; i < recipes.length; ++i) {
        const actR = recipes[i];
        const name = actR?.recipe?.description?.es || "";
        if (type === "Main") {
            if (actR?.extraMeal) 
                description += `${description !== `` ? ", " : ""}${name}`;
        }
        else description += `${description !== `` ? ", " : ""}${name}`;
    }
    if (description === ``) description = "There is no intakes";
    return description
}

function exerciseFormat(cell, row) {
    let description = `${cell} | ${row?.totalGChoH || 0} gCHO/h - ${row?.totalMlh || 0} mL/h`;
    // console.log(row)
    return description;
}

export function descriptionFormatter(cell, row) {
    const type = row?.type;
    if (type === "Exercise") return exerciseFormat(cell, row);
    return intakeFormat(row, type);
}