import { API, authClient } from "../index";

export const getForms = () => {
	return authClient().get(`${API}/form?getDisabled=true`);
};

export const getFormById = (id) => {
	return authClient().get(`${API}/form/${id}`);
};

export const deleteForm = (id) => {
	return authClient().delete(`${API}/form/${id}`);
};

export const postForm = async (form) => {
	return authClient().post(`${API}/form`, form);
};

export const updateForm = async (id, form) => {
	return authClient().put(`${API}/form/${id}`, form);
};

export const changeStatusForm = async (id, active) => {
	return authClient().put(`${API}/form/change-status/${id}`, {
		active,
	});
};
