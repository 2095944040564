import React from "react";
import { Col, Row } from "react-bootstrap"

export function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value)
}

export const delay = (n) => new Promise( r => setTimeout(r, n*1000))

export const getNonEmpty = (field) => {
	const defaultLanguage = 'es'
	if (!field || !Object.keys(field)?.length) return '----'
	if (field[defaultLanguage] && field[defaultLanguage] !== '') return field[defaultLanguage]
	for (const lang of Object.keys(field)) {
		if (field[lang] && field[lang] !== '') return field[lang]
	}
	return '----'
}

export const checkIsEmpty = (field) => {
	return Object.values(field).every(x => x === null || x === '' || x === ' ');
}

export function getHours(time) {
	if (!time) return 0;
	const splitTime = time.split(':');
	const mins = parseFloat(splitTime[0]) + parseFloat(splitTime[1]) / 60;
	return mins;
}


export function getMins(time) {
	if (!time) return 0;
	const splitTime = time.split(':');
	const mins = parseFloat(splitTime[0]) * 60 + parseFloat(splitTime[1]);
	return mins;
}

export function formatHourMins(time) {
	if (!time) return '00:00';
	const h = Math.floor(time / 60);
	const m = time % 60;
	return `${h < 10 ? `0${h}`: h}:${m < 10 ? `0${m}` : m}`;
}

export function formatInteger(num) {
	num = parseFloat(num);
	return num.toFixed(0);
}

export function formatFloat(num) {
	num = parseFloat(num);
	return (num % 1 === 0)? num : parseFloat(num.toFixed(1));
}

export function formatFixedFloat(num, fix) {
	num = parseFloat(num);
	return (num % 1 === 0)? num : num.toFixed(fix);
}

export const getFileType = (fileName) => {
	if (!fileName) return 'unknown'
	const ext = fileName.split('.').pop()
	switch (ext){
	  case 'pdf':
		return 'file'
	  case 'm4a':
		return 'audio'
	  case 'mp3':
		return 'audio'
	  case 'mpa':
		return 'audio'
	  case 'wma':
		return 'audio'
	  case 'aif':
		return 'audio'
	  case 'cda':
		return 'audio'
	  case 'mid':
		return 'audio'
	  case 'midi':
		return 'audio'
	  case 'ogg':
		return 'audio'
	  case 'wav':
		return 'audio'
	  case 'wpl':
		return 'audio'
	  case 'jpg':
		return 'image'
	  case 'jpeg':
		return 'image'
	  case 'png':
		return 'image'
	  case 'svg':
		return 'image'
	  case 'gif':
		return 'image'
	  case 'avi':
		return 'video'
	  case 'm4v':
		return 'video'
	  case 'mkw':
		return 'video'
	  case 'mp4':
		return 'video'
	  case 'wmv':
		return 'video'
	  case 'mov':
		return 'video'
	  default:
		return 'unknown'
	}
  }

  export const getEmptySchedule = () => {
	return {
		Monday: "",
		Tuesday: "",
		Wednesday: "",
		Thursday: "",
		Friday: "",
		Saturday: "",
		Sunday: "",
	};
  };

  export function removeHtmlTags(string) {
	const doc = new DOMParser().parseFromString(string, "text/html");
	return doc.body.textContent || doc.body.innerText || "";
  }

  export function TableHeader({title, onClick, numberCol1, addText}) {
	
	return (
		<Row>
			<Col sm={numberCol1}><h5>{title}</h5></Col>
			<Col className="text-right">
				<button
					type="button"
					className="btn btn-primary"
					onClick={() => onClick()}
				>
					{addText || "Add new"}
				</button>
			</Col>
		</Row>
	)
  }