import { Button, FormControlLabel, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { getNonEmpty } from "../../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../../utils/logger";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../../components/tables/table";
import { deleteUserDocument, getUserDocumentsByUserId, postUserDocuments, updateUserDocuments } from "../../../../../api/userDocuments";
import EditPatientDocument from "./EditPatientDocument";
import { getDocumentsTags } from "../../../../../api/documentsTag";
import { Visibility } from "@material-ui/icons";
import { SERVER_URL } from "../../../../../api";
import FiltersCard from "../../../../components/filters/Filter";
import MyAutocomplete from "../../../../components/MyAutocomplete";

const initialFilters = {
	initUpload: null,
  endUpload: null,
  initDoc: null,
  endDoc: null,
  tag: null
}

export default function EditPatientDocuments(props) {
  const { userId } = props;
	const [data, setData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
  const [openEditPatientDocument, setOpenEditPatientDocument] = useState(false);
  const [newDocument, setNewDocument] = useState(false);
  const [tags, setTags] = useState([]);
  
	const [collapsed, setCollapsed] = useState(true);
	const [filteredData, setFilteredData] = useState([]);
	const [filterOptions, setFilterOptions] = useState(initialFilters);

	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

  function getEmptyUserDocuments() {
    return {
      userId: userId,
      documents: [],
      active: true
    } 
  }

  function getEmptyDocument() {
    const newDate = new Date();
    const formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    return {
      _id: data?.documents?.length || 0,
      title: "",
      description: "",
      createdAt: formatDate,
      fileURL: null,
      active: true
    }
  }

  useEffect(() => {
      if (!data) getUserDocumentsByUserId(userId)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data);
            setFilteredData(res.data.documents)
          }
        })
        .catch((error) => {
          if (error.request.status !== 404) {
            alertError({
              error: error,
              customMessage: "Could not get user documents"
            })
          } else {
            setData(getEmptyUserDocuments());
          }
        })
  }, [userId])

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    getDocumentsTags()
      .then((res) => {
        setTags(res.data);
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          alertError({
            error: error,
            customMessage: "Could not get tags"
          })
        }
      })
  }, [])

	const columns = [
		{
			dataField: "name",
			text: "Full name",
			sort: true,
		},
		{
			dataField: "uploadAt",
			text: "Uploaded at",
			formatter: dateFormatter,
      headerAlign: 'center',
      headerStyle: { width: '125px' },
      align: 'center',
			sort: true,
		},
		{
			dataField: "docDate",
			text: "document date",
			formatter: dateFormatter,
      headerAlign: 'center',
      headerStyle: { width: '125px' },
      align: 'center',
			sort: true,
		},
		{
			dataField: "docName",
			text: "Doc name",
      headerAlign: 'center',
      align: 'center',
      headerStyle: { width: '150px' },
			sort: true,
		},
		{
			dataField: "tag",
			text: "Tags",
      headerAlign: 'center',
      align: 'center',
			sort: true,
		},
		{ 
      dataField: "_id", 
      text: "", 
      align: 'right',
      headerStyle: { width: '200px' },
      formatter: buttonFormatter
    },
	];

  function buttonFormatter(cell, row) {
		const index = data?.documents?.findIndex((item) => item.title === row.name);
    const elem = (index !== -1)? data?.documents[index] : null;
		return (
			<>
        { getType(elem?.fileURL) && (
          <Tooltip title={"View"}>
          <Button
            size="small"
            onClick={() => {
              window.open(`${SERVER_URL}/${elem.fileURL}`, '_blank');
            }}
						style={buttonsStyle}
          >
            <Visibility />
          </Button>
        </Tooltip>
        )}
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
              setSelectedDocument(elem);
              setOpenEditPatientDocument(true);
              setNewDocument(false);
            }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                  let newDocuments = [...data?.documents];
                  newDocuments[index].active = !elem?.active;
                  setData({
                    ...data,
                    documents: newDocuments
                  });
                  setRefresh(true);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "#00F" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenConfirmDialog(2);
                  setSelectedDocument(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

  const handleSearch = async () => {
    if (!data?.documents?.length) return;

    let initUpload = filterOptions?.initUpload;
		if (initUpload) initUpload = new Date(initUpload);
		let endUpload = filterOptions?.endUpload;
		if (endUpload) {
			endUpload = new Date(endUpload);
			endUpload.setHours(23, 59, 59, 999);
		}

    let initDoc = filterOptions?.initDoc;
		if (initDoc) initDoc = new Date(initDoc);
		let endDoc = filterOptions?.endDoc;
		if (endDoc) {
			endDoc = new Date(endDoc);
			endDoc.setHours(23, 59, 59, 999);
		}

    const tag = filterOptions?.tag;
		setFilteredData(data?.documents?.filter(item => {
			let filter = true;
      const uploadDate = new Date(item?.uploadAt);
      const docDate = new Date(item?.docDate);
      console.log(uploadDate, docDate)
      if (initUpload && uploadDate) filter = filter && uploadDate >= initUpload;
			if (endUpload && uploadDate) filter = filter && uploadDate <= endUpload;
      if (initDoc && docDate) filter = filter && docDate >= initDoc;
			if (endDoc && docDate) filter = filter && docDate <= endDoc;
      if (tag) {
        let found = false;
        for (let i = 0; i < item?.tags?.length; ++i) 
          if (item.tags[i].name === tag) found = true;
        filter = filter && found;
      }
			if (filter) return item;
			return false;
		}))
  }

  const handleClearFilters = () => {
		setFilterOptions({
      ...initialFilters,
      initUpload: null,
      endUpload: null,
      initDoc: null,
      endDoc: null
    });
		setRefresh(true);
	}

  const DateField = ({title, date}) => {
    return (
      <TextField
        id={`${date}`}
        label={title}
        value={(filterOptions[date])}
        onChange={handleChange(date)}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        variant="standard"
        type='date'
        required
      />
    )
  }

  const renderFiltersContent = () => {
		return <>
			<br/>
      {!refresh && (
        <>
          <FormControlLabel 
            control={<DateField title="Start uploaded date" date={"initUpload"}/>}
						style={{width: "200px"}}
          />
          <FormControlLabel 
            control={<DateField title="End uploaded date" date={"endUpload"}/>}
						style={{width: "200px"}}
          />
          <FormControlLabel 
            control={<DateField title="Start document date" date={"initDoc"}/>}
						style={{width: "200px"}}
          />
          <FormControlLabel 
            control={<DateField title="End document date" date={"endDoc"}/>}
						style={{width: "200px"}}
          />
          <FormControlLabel 
            control={
              <MyAutocomplete 
                options={tags}
                getOptionLabel={(option) => option.name}
                value={
                  tags.find((x) => x.name === filterOptions?.tag) 
                  || null
                }
                onChange={(event, selected) => {
                  setFilterOptions({...filterOptions, tag: selected.name})
                }}
                placeholder="Select tag"
                label={""}
                style={{flex: 1, marginLeft: '0px', marginTop: '16px'}}
              />
            }
						style={{width: "200px"}}
          />
        </>
      )}
		</>
	}

  const handleChange = (element) => (event) => {
		const { value } = event.target;
		setFilterOptions({
			...filterOptions,
			[element]: value
		})
	}

  function getType(url) {
		if (url) {
			const splitted = url.split('.');
			return splitted[splitted.length-1];
		}
		return '';
	}

  function getDataDocuments(docs) {
    function getTags(document) {
      const tags = [...document.tags];
      if (tags.length > 0) {
        let text = `${tags[0].name}`;
        for (let i = 1; i < tags.length; ++i) text += `, ${tags[i].name}`
        return text;
      }
      return '';
    }
    
    let data = [];

    for (let i = 0; i < docs?.length; ++i) {
      let elem = {};
      const actD = docs[i];
      
      elem.id = elem?._id;
      elem.name = actD?.title || "No title";
      elem.createdAt = actD?.createdAt || "";
      elem.tag = getTags(actD);
      if (actD?.fileURL) elem.docName = actD.fileURL?.split(/-(.*)/s)[1];
      elem.uploadAt = actD?.uploadAt;
      elem.docDate = actD?.docDate;

      data.push(elem);
    }

    return data;
  }

  async function saveDocument(data, document, file) {
    let index = data?.documents?.findIndex((x) => x._id === document?._id)
    let newData = data;
    if (index !== -1) newData.documents[index] = document;
    else {
      newData.documents.push(document);
      index = newData.documents.length-1;
    }
    if (data?._id) {
      await updateUserDocuments(data._id, newData, index, file)
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not update user documents"
          })
          return false;
        });
      setData({...newData});
    } else {
      const res = await postUserDocuments(newData, index, file)
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not update user documents"
          })
          return false;
        });
      setData({...newData, _id: res.data._id});
    }
    alertSuccess({
      title: "Saved!",
      customMessage: "Document saved succesfully!"
    })
    return true;
  }

  async function deleteDocument(data, document) {
    let index = data?.documents?.findIndex((x) => x._id === document?._id)
    let newData = data;
    if (index !== -1) newData.documents.splice(index, 1);
    else {
      alertError({
        error: "Error!",
        customMessage: "Could not delete document"
      })
    }
    if (data?._id) {
      await deleteUserDocument(data._id, data, index)
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not delete document"
          })
          return false;
        });
    } else {
      alertError({
        error: "Error!",
        customMessage: "Could not delete document"
      })
    }
    alertSuccess({
      title: "Saved!",
      customMessage: "Document deleted succesfully!"
    })
    setData({...newData});
    setRefresh(true);
    return true;
  }

  if (openEditPatientDocument) return (
    <EditPatientDocument 
    data={selectedDocument}
    setOpen={setOpenEditPatientDocument}
    newDocument={newDocument}
    allTags={tags}
    setAllTags={setTags}
    onSave={async (document, file) => {
      return await saveDocument(data, {...document}, file);
    }}
    onDelete={async (document) => {
      return await deleteDocument(data, document)
    }}
    />
  )
	return (
		<>
			<Card>
				<CardHeader title="Documents">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
                setSelectedDocument(getEmptyDocument());
                setOpenEditPatientDocument(true);
                setNewDocument(true);
              }}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
          <FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>
					{!refresh && (<Table data={getDataDocuments(filteredData, data?.documents)} columns={columns} />)}
					<ConfirmDialog
						title={"Are you sure you want to remove this document?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteDocument(data, selectedDocument)
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
