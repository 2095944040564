import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { alertError } from "../../../../utils/logger";
import MyAutocomplete from "../../MyAutocomplete";
import { typeOptionsN, textureOptionsN, flavourOptionsN, isLiquid, getNts } from "../../../../utils/sportFoodUtils";
import { getBrands } from "../../../../api/brand";
import { formatFloat } from "../../../../utils/helpers";

function getEmptyFood() {
	return {
        food: null,
        name: null,
        quantity: null,
        number: null,
        observations: "",
        ml: 0,
        quantity: 0,
        selectedPortion: -1,
	};
}

const initFilters = {
    brand: null,
    type: null,
    texture: null,
    flavour: null
}

const EditTramFoodDialog = (props) => {
	const { title, data, open, setOpen, onSave, foodOptions } = props;

    const [food, setFood] = useState(null);
    const [nutritionals, setNutritionals] = useState(null);
    const [brandOptions, setBrandOptions] = useState([]);
    const [foodFilter, setFoodFilter] = useState(initFilters);
    const [foodOptionsF, setFoodOptionsF] = useState(foodOptions);
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		if (!open || !data) setFood(getEmptyFood());
		else setFood(data);
	}, [data, open]);

    useEffect(() => {
        setRefresh(false)
    }, [refresh]);

    useEffect(() =>{
        getBrands()
            .then((res) => {
                let data = [];
                data.push({name: "None", _id: 0});
                data = data.concat(res.data);
                setBrandOptions(data);
            })
            .catch((error) => {
                if (error.response.status !== 404)
                    alertError({
                        error: error,
                        customMessage: "Could not get brands."
                    })
            })
    }, [])

    useEffect(() => {
        refreshFoodFilter();
    }, [foodFilter])

    useEffect(() => {
        refreshNts();
    }, [food]);

    function refreshNts() {
        const { waterMl, choG, sodiumMg, caffeine } = getNts(food?.food, food?.ml, food?.quantity)
        setNutritionals({
            water: waterMl,
            carbohydrates: choG,
            sodium: sodiumMg,
            caffeine
        });
    }

    function refreshFoodFilter() {
        const texture = (foodFilter.texture === "None") ? null : foodFilter.texture;
        const flavour = (foodFilter.flavour === "None") ? null : foodFilter.flavour;
        const type = (foodFilter.type === "None") ? null : foodFilter.type;
        const brand = (foodFilter.brand === "None") ? null : foodFilter.brand;
        
        const items = foodOptions.filter(item => {
            let filter = true; 
            if (texture) filter = filter && (item?.texture === texture);
            if (flavour) filter = filter && (item?.flavour === flavour);
            if (type) filter = filter && (item?.type === type);
            if (brand) filter = filter && (item?.brand?._id === brand);
            return filter;
        })

        setFoodOptionsF(items);
    }

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
        else {
			if (!food[element]) food[element] = {};
			let newText = food[element];
			newText = event.target.value;
			setFood({ ...food, [element]: newText });
		} 

        setRefresh(true);
	};

    function foodAutocomplete(title, element, options) {
        return (
            <MyAutocomplete
                options={options}
                getOptionLabel={(option) => option}
                value={foodFilter[element] || ""}
                onChange={(event, selected) => {
                    setFoodFilter({
                        ...foodFilter,
                        [element]: selected,
                    });
                }}
                placeholder={`Select ${element}`}
                label={`${title}`}
            />
        )
    }

    function NutritionalValues() {

        function ReadOnly(title, value) {
            return (
                <TextField
                    id={`${title}`}
                    label={title}
                    value={formatFloat(nutritionals[value])}
                    className="readonly"
                    inputProps={{readOnly: true}}
                    margin="normal"
                    variant="standard"
                    required
                    type="number"
                />
            )
        }

        return (
            <Row>
                <Col>{ReadOnly("Water (mL)", "water")}</Col>
                <Col>{ReadOnly("CHO (g)", "carbohydrates")}</Col>
                <Col>{ReadOnly("Na (mg)", "sodium")}</Col>
                <Col>{ReadOnly("Caf (mg)", "caffeine")}</Col>
            </Row>
        )
    }

    return (
		<Dialog disableBackdropClick={true} 
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<br />
                
                <Row>
                    <Col>
                    {foodAutocomplete("Type of product", "type", typeOptionsN)}
                    </Col>
                    <Col>
                    {foodAutocomplete("Texture", "texture", textureOptionsN)}
                    </Col>
                    <Col>
                    {foodAutocomplete("Flavour", "flavour", flavourOptionsN)}
                    </Col>
                    <Col>
                    <MyAutocomplete
                        options={brandOptions}
                        getOptionLabel={(option) => option.name}
                        value={brandOptions.find((x) => x._id === foodFilter?.brand) || ""}
                        onChange={(event, selected) => {
                            setFoodFilter({
                                ...foodFilter,
                                brand: selected._id,
                            });
                        }}
                        placeholder={"Select Brand"}
                        label={"Brand"}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <MyAutocomplete
                        options={ foodOptionsF }
                        getOptionLabel={(option) =>
                            option.name
                        }
                        value={ foodOptionsF.find(option => option._id === food?.food?._id) || null }
                        onChange={(event, selected) => {
                            const newFood = {
                                ...food,
                                name: selected.name,
                                food: selected
                            }
                            setFood(newFood)
                            setRefresh(true)
                        }}
                        placeholder="Select food"
                        label={"Select sport food"}
                    />
                    </Col>
                </Row>

                <Row>
                    <Col>
                    { isLiquid(food?.food) ?
                        <TextField
                            id={`ml`}
                            label="Quantity (mL)"
                            value={food?.ml}
                            onChange={handleChange("ml")}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter quantity..."
                            required
                            type="number"
                        />
                    : 
                        <TextField
                            id={`quantity`}
                            label="Quantity (ud)"
                            value={food?.quantity}
                            onChange={handleChange("quantity")}
                            InputProps={{
                                inputProps: {
                                    min: 0
                                }
                            }}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter quantity..."
                            required
                            type="number"
                        />
                    }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            id={`observation`}
                            label="Observation"
                            value={food?.observations}
                            onChange={handleChange("observations")}
                            InputLabelProps={{
                                shrink: true
                            }}
                            margin="normal"
                            variant="standard"
                            placeholder="Enter observation..."
                            required
                            type="text"
                        />
                    </Col>
                </Row>
                <NutritionalValues />
                <br/><br/><br/><br/><br/><br/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setFood(getEmptyFood());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
                        console.log(isLiquid(food), food)
                        if (!food?.name) {
                            alertError({
                                error: null,
                                customMessage: "The food is required.",
                            });
                        }
                        else if ((isLiquid(food?.food) && !food?.ml) || (!isLiquid(food?.food) && !food?.quantity)) {
                            alertError({
                                error: null,
                                customMessage: "Quantity is required.",
                            });
                        } else {
                            setOpen(false);
                            onSave({...food,});
                            setFood(getEmptyFood());
                        }
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditTramFoodDialog;